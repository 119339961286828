import { Fragment, lazy } from 'react';
import Main from '../../components/layout/Main';

const noContext: React.FC<{ children: any }> = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export const chatRoutes = [
  {
    path: '/chat',
    exact: true,
    page: ['chat'],
    component: lazy(() => import('../../pages/chat')),
    layout: Main,
    role: ['Superuser', 'Outlet', 'Owner'],
    title: 'Chat',
    context: noContext,
  },
];
