import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import imgBg from '../../assets/icons/background.svg';
import { URequestLogout } from '../../usecase/auth.usecase';
import { getFcmToken, getUser } from '../../utils/localStorage';
import './index.scss';
import BoxProfile from './section/Box.Profile';
import DropdownProfile from './section/Drodown.Profile';
import { checkDetailProfile } from './section/utils';

interface Props {
  collapsed: boolean;
  setCollapsed: (flag: boolean) => void;
  isMobile: boolean;
  breadcrumb: Array<any>;
  title: string;
  isUpdateUser?: boolean;
}

const { Header } = Layout;

const Index: React.FC<Props> = (props) => {
  const { collapsed, setCollapsed, isMobile, breadcrumb, title, isUpdateUser } = props;
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<{
    box: boolean;
    name: string;
    status: string;
    role: string;
  }>();

  const user = getUser();

  useEffect(() => {
    async function checkUser() {
      const res = await checkDetailProfile(user);

      setCurrent({ box: res.box, name: res.name, status: res.status, role: res.role });
    }

    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUser]);

  const handleLogout = () => {
    try {
      const params = {
        fcm_token: getFcmToken(),
      };

      dispatch(URequestLogout(params));
    } catch (error) {
      console.log(error);
    }
  };

  const widthBoxHeader = !isMobile ? { width: `calc(100% - 220px)` } : { width: `100%` };

  return (
    <Header className="box-header">
      <img className="img-box-header" src={imgBg} alt="" />
      <div className="d-flex">
        {!isMobile && <BoxProfile current={current} />}
        <div className="header-container" style={widthBoxHeader}>
          <div className="box-title">
            <div style={{ paddingLeft: 16 }}>
              <h2>{title}</h2>
              <Breadcrumb>
                {breadcrumb.map((val: string, index: number) => (
                  <Breadcrumb.Item
                    key={index}
                    className={index === breadcrumb.length - 1 ? 'end' : ''}>
                    {val}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
            <div className="d-flex align-items-center">
              {isMobile && (
                <Button
                  type="text"
                  onClick={() => setCollapsed(!collapsed)}
                  style={{ color: 'white' }}>
                  <MenuUnfoldOutlined />
                </Button>
              )}
            </div>
          </div>
          <DropdownProfile isMobile={isMobile} handleLogout={handleLogout} current={current} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  isUpdateUser: state.layout.isUpdateUser,
});

export default connect(mapStateToProps, null)(Index);
