import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllListKategori } from '../../../data/kategori/kategori';
import {
  UCreateBanner,
  UGetDetailBanner,
  UUpdateBanner,
  UUploadImageBanner,
} from '../../../usecase/marketing/banner.usecase';
import { formBanner, IFormBanner } from '../model.marketing';
import { createBodyPayloadBanner, setDetailBanner } from '../utils/refernces.banner';

export const useModelBannerForm = () => {
  const { id } = useParams();
  const [form, setForm] = useState<IFormBanner>(formBanner);
  const [listLayanan, setListLayanan] = useState<Array<any>>([]);
  const [listSubLayanan, setListSubLayanan] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function fetchLayanan() {
    const temp: any = await getAllListKategori({ type: 'layanan' });
    setListLayanan(temp.data || []);
  }

  async function fetchSubLayanan(parent_id: string) {
    if (!parent_id) return setListSubLayanan([]);
    const temp: any = await getAllListKategori({ type: 'sub-layanan', parent_id });
    setListSubLayanan(temp.data || []);
  }

  useEffect(() => {
    fetchLayanan();
  }, []);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const res: any = await dispatch(UGetDetailBanner(id));
      const data = res.data as IFormBanner;

      setForm(setDetailBanner({ ...data }));
      if (data.position_place === 'outlet_group') {
        const propertyId = (data.property_id || []).join(',');
        fetchSubLayanan(propertyId);
      }
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: any) => {
    const payload = createBodyPayloadBanner({ ...values, is_active: form.is_active });

    try {
      if (id) {
        await dispatch(UUpdateBanner(String(id), payload));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        await dispatch(UCreateBanner(payload));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Banner',
          callback: () => navigate(-1),
        });
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  const handleUploadImage = async (data: any) => {
    const formData = new FormData();
    formData.append('files', data);

    try {
      const res: any = await dispatch(UUploadImageBanner(formData));

      return res.data[0];
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleUploadImage,
    listLayanan,
    listSubLayanan,
    handleChangeLayanan: fetchSubLayanan,
    handleSubmit,
  };
};
