import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { checkMenuRoles } from '../../components/Sider/utils';
import { SERVICE_ORDER } from '../../config/keys';
import httpService from '../../services/http.service';
import { getUser } from '../../utils/localStorage';
import { enumRole } from '../../utils/pages';

const currentUser = getUser();
const userRoles = checkMenuRoles(currentUser?.roles);
const accessService = userRoles === enumRole.SUPERUSER ? 'admin' : 'outlet';

export const getlistTransaksiReview = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/orderReview`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailTransaksiReview = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/orderReview/id/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const exportTransaksiReview = async () => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/orderReview/export`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
