import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UGetlistInvoiceVoucherTransaction } from '../../../usecase/marketing/voucher.transaction.usecase';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsVoucherListInvoice } from '../model.marketing';

interface Props {
  user_id: string;
}

export const useModelVoucherListInvoice = (props: Props) => {
  const { id = '' } = useParams();
  const { user_id } = props;
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsVoucherListInvoice>({
    page: 1,
    limit: 10,
    sort_by: 'created_at',
    order: 'asc',
    voucher_id: id,
    user_id,
    status: 'done',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  async function fetchList(params: any) {
    setLoading(true);
    try {
      const temp: any = await dispatch(UGetlistInvoiceVoucherTransaction(params));
      setDataRequest({
        data: temp.data,
        pagination: temp.meta,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleChangePage,
    loading,
  };
};
