import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { SERVICE_MARKETING } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistInvoiceVoucherTransaction = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher-transaction`, {
      params,
    });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getlistVoucherTransaction = async (params: any, id: string) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher-transaction/${id}/voucher`, {
      params,
    });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailVoucherTransaction = async () => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher-transaction/me`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
