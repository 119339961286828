import { layoutTypes } from './notification.types';

const initState = {
  update: false,
};

export const notificationReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case layoutTypes.UPDATE:
      return {
        ...state,
        update: action.payload,
      };

    default:
      return state;
  }
};
