import React, { Fragment } from 'react';
import logo from '../../../assets/icons/MO_Logo_big.svg';
import profileIcon from '../../../assets/icons/profile-icon.svg';

import { getFlagRole } from './utils';

export interface ICurrent {
  box: boolean;
  name: string;
  status: string;
  role?: string;
}

interface Props {
  isMobile?: boolean;
  current: ICurrent | undefined;
}

const BoxProfile: React.FC<Props> = (props) => {
  const {
    isMobile,
    current = {
      box: false,
      name: '',
      status: '',
      role: '',
    },
  } = props;

  const styleText = isMobile ? { color: 'white' } : {};

  return (
    <div className="box-user">
      <img className="mt-4" width={144} src={logo} alt="logo" />
      <br />
      <img className="mt-2" width={60} src={profileIcon} alt="profile-icon" />
      <br />
      <h5 className="mb-0" style={styleText}>
        {current.name}
      </h5>
      <h6 className="text-flag-profile" style={styleText}>
        {current.box && (
          <Fragment>
            <span>
              <img width={10} src={getFlagRole(current)} alt="flag" />
            </span>
            {current.status}
          </Fragment>
        )}
      </h6>
    </div>
  );
};

export default BoxProfile;
