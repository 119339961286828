import { Badge, Button, Divider, Dropdown, Menu } from 'antd';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import bell from '../../../assets/icons/bell-notification.svg';
import { useNotificationViewModel } from '../model/useNotificationViewModel';
import CardNotificationPopup from './component/CardNotificationPopup';

interface Props {
  role: string;
  updateNotification?: boolean;
}

const Notif: React.FC<Props> = ({ role, updateNotification }) => {
  const { handleNotification, navigate, data, handleClickNotif } = useNotificationViewModel({
    role,
    updateNotification,
  });

  const menu = (
    <Menu style={{ width: 375 }} className="p-3">
      <h5 className="mb-0">Notifikasi</h5>
      <Divider className="my-3" />

      <div>
        {data.list && data.list.length === 0 ? (
          <p className="text-muted" onClick={handleNotification}>
            <i>Belum ada notifikasi</i>
          </p>
        ) : (
          data.list.map((val: Array<any>) => (
            <Fragment key={val[0]}>
              <p className="text-muted">{val[0]}</p>

              {val[1].map((x: any) => (
                <CardNotificationPopup
                  key={x.id}
                  description={x.content}
                  title={x.title}
                  isRead={x.is_read}
                  onClick={() => handleClickNotif(x)}
                />
              ))}
            </Fragment>
          ))
        )}
      </div>

      <div className="text-center">
        <Button type="link" onClick={() => navigate('/notification')}>
          Lihat Semua
        </Button>
      </div>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="custom-dropdown-notif">
      <div className="me-3">
        <Badge count={data.count} size="small">
          <img width={20} src={bell} alt="profile-icon" />
        </Badge>
      </div>
    </Dropdown>
  );
};

const mapStateToProps = (state: any) => ({
  updateNotification: state.notification.update,
});

export default connect(mapStateToProps, null)(Notif);
