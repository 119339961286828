import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UUpdatePassword } from '../../../usecase/user-management/user.usecase';
import { IFormChangePassword } from '../model.profile';

export const useModelFormChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values: IFormChangePassword) => {
    try {
      await dispatch(UUpdatePassword(values));

      navigate('/dashboard');
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return {
    handleSubmit,
  };
};
