import {
  getDetailNotification,
  getlistNotification,
  registerTokenNotification,
  updateAllRead,
} from '../../data/notification/notification';
import { setLoading } from '../../redux/layout/layout.actions';

export const URegisterTokenNotification = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await registerTokenNotification(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UGetlistNotification = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getlistNotification(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UGetDetailNotification = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailNotification(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateAllRead = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateAllRead();
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
