import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UGetDetailTransaksiReview } from '../../../usecase/merchant/review.usecase';
import { formReview, IFormReview } from '../model.merchant';

export const useModelReviewForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormReview>(formReview);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailTransaksiReview(id));
      setForm(temp.data);
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    form,
  };
};
