import {
  ErrorNotificationNoValiation,
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistKaryawan = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-employee`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListKaryawan = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-employee/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailKaryawan = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-employee/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createKaryawan = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/merchant-employee`, data);
    // SuccessNotification({ description: 'Karyawan berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotificationNoValiation(error);
  }
};

export const updateKaryawan = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/merchant-employee/${id}`, data);
    // SuccessNotification({ description: 'Karyawan berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotificationNoValiation(error);
  }
};

export const deleteKaryawan = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/merchant-employee/${id}`);
    SuccessNotification({ description: 'Karyawan berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailKaryawanMap = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-employee/user/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
