import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistKaryawanMapping = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-employee-mapping`, { params });

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getDetailKaryawanMapping = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-employee-mapping/${id}`);

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const createKaryawanMapping = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/outlet-employee-mapping`, data);

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const updateKaryawanMapping = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/outlet-employee-mapping/${id}`, data);

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const deleteKaryawanMapping = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/outlet-employee-mapping/${id}`);

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
