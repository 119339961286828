import React, { createContext } from 'react';
import { useModelSettingList } from './context.setting.list';

export const ContextSetting = createContext<any>(null);

const ContextProviderSetting: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelSettingList,
  };

  return <ContextSetting.Provider value={context}>{props.children}</ContextSetting.Provider>;
};

export default ContextProviderSetting;
