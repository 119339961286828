import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../../data/user-management/user';
import { UUpdateCurrentUser } from '../../../usecase/user-management/user.usecase';
import { formProfile, IFormProfile } from '../model.profile';
import { setPhoneNumber } from '../utils';

export const useModelFormProfile = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<IFormProfile>(formProfile);
  const dispatch = useDispatch();

  const handleSubmit = async (values: IFormProfile) => {
    const formData = {
      name: values.name,
      email: values.email,
      phone_number: `0${values.phone_number}`,
    };

    try {
      await dispatch(UUpdateCurrentUser(form.id, formData));
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchCurrentUser() {
      const res: any = await getCurrentUser();

      setForm({
        ...form,
        ...res.data,
        phone_number: setPhoneNumber(res.data.phone_number),
      });
    }

    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    handleSubmit,
  };
};
