import {
  createKaryawan,
  deleteKaryawan,
  getAllListKaryawan,
  getDetailKaryawan,
  getDetailKaryawanMap,
  getlistKaryawan,
  updateKaryawan,
} from '../../data/user-management/karyawan';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistKaryawan = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistKaryawan(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListKaryawan = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListKaryawan(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailKaryawan = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailKaryawan(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateKaryawan = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createKaryawan(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateKaryawan = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateKaryawan(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteKaryawan = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteKaryawan(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailKaryawanMap = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailKaryawanMap(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UGetIsKaryawanLogin = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const {
      data: { is_account_logged },
    } = await getDetailKaryawan(id);
    return is_account_logged;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
