import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { formOutlet, IFormOperationalHour, IFormOutlet } from '../model.merchant';
import {
  UCreateMerchantOutlet,
  UGetDetailMerchantOutlet,
  UUpdateMerchantOutlet,
} from '../../../usecase/merchant/outlet.usecase';
import { getlistAllMerchant } from '../../../data/merchant/merchant.option';
import { getAllListKategori } from '../../../data/kategori/kategori';
import { getPartnerPayment } from '../../../data/transaksi/transaksi.masuk';
import { IFilter, IItemResponse } from '../models/outlet.model';
import {
  IPaginationDefaultFilter,
  IPaginationMeta,
  IPaginationResponse,
} from '../../../models/pagination.model';
import { getlistItem } from '../../../data/items/item';
import { getAllListKategoriOutlet } from '../../../data/merchant/kategori.outlet';
import { pluck } from '../../../utils/helper';
import { sortOperationalHour } from '../utils/reference.outlet';

type CombinationFilter = IFilter & IPaginationDefaultFilter;
export const useModelOutletForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormOutlet>(formOutlet);
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);
  const [listOutletGroup, setListOutletGroup] = useState<Array<any>>([]);
  const [listPartnerPayment, setListPartnerPayment] = useState<Array<any>>([]);
  const [listCategoryOutlet, setListCategoryOutlet] = useState<Array<any>>([]);
  const [listActiveItems, setListActiveItems] = useState<IItemResponse[]>([]);
  const [listActiveFilterPagination, setActiveFilterPagination] = useState<IPaginationMeta>({
    page: 1,
    limit: 10,
    order: '',
    sort_by: '',
    total_data: 0,
    total_pages: 0,
  });
  const [listActiveFilter, setListActiveFilter] = useState<CombinationFilter>({
    is_active: 1,
    limit: 10,
    merchant_id: '',
    merchant_outlet_id: '',
    merchant_product_category_id: '',
    order: '',
    page: 1,
    q: '',
    sort_by: '',
  });
  const [showPromptDraft, setShowPromptDraft] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [isEditValue, setIsEditValue] = useState<boolean>(false);

  const handleShowPromptDraft = (flag: boolean) => setShowPromptDraft(flag);
  const handleShowPrompt = (flag: boolean) => setShowPrompt(flag);

  useEffect(() => {
    const fetchListOption = async (paramsMerchant: any) => {
      const tempMerchant: any = await getlistAllMerchant(paramsMerchant);
      setMerchantList(tempMerchant.data);
    };
    fetchOutletGroup();
    fetchPartnerPayment(null);
    fetchListOption({ is_active: 1, sort_by: 'created_at', order: 'desc' });
    loadActiveItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadActiveItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listActiveFilter]);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailMerchantOutlet(id));
      await handleChangeLayanan(temp.data.outlet_group_id);
      const location: string = temp.data.location.replace(/^\((.+)\)$/, '$1');

      setForm({
        ...temp.data,
        location,
        lat: location.split(',')[0],
        long: location.split(',')[1],
        percentage_sharing_fee: String(temp.data.percentage_sharing_fee),
        temp_merchant_outlet_operational_hours: sortOperationalHour([
          ...temp.data.merchant_outlet_operational_hours,
        ]),
        outlet_category_id:
          temp.data.merchant_outlet_category_mapping.length > 0
            ? pluck(temp.data.merchant_outlet_category_mapping, 'outlet_category_id') ?? []
            : [],
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const loadActiveItems = () => {
    getlistItem(listActiveFilter).then((res: any) => {
      const response: IPaginationResponse = res;
      setListActiveItems(response.data);
      setActiveFilterPagination(response.meta);
    });
  };
  const handleFilterActiveItem = (key: any, value: any) => {
    setListActiveFilter({
      ...listActiveFilter,
      [key]: value,
    });
  };
  const onChangeOperationalHour = async (value: any) => {
    if (value === form.merchant_outlet_operational_hours) {
      setForm({
        ...form,
        temp_merchant_outlet_operational_hours: value.map((val: any) => ({ ...val })),
      });
    } else {
      setForm({
        ...form,
        merchant_outlet_operational_hours: value.map((val: any) => ({ ...val })),
      });
    }
  };

  const handleSubmit = async (values: IFormOutlet) => {
    values.location = form.location;
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('address', values.address);
    formData.append('fixed_sharing_fee', String(values.fixed_sharing_fee));
    formData.append('phone_number', String(values.phone_number));
    formData.append('description', String(values.description));
    formData.append('location', String(values.location));
    formData.append('type_delivery', String(values.type_delivery));
    formData.append('outlet_group_id', String(values.outlet_group_id));
    formData.append('payment_partner_code', String(values.payment_partner_code));
    formData.append('percentage_sharing_fee', values.percentage_sharing_fee);
    formData.append('operational_coverage_area', String(values.operational_coverage_area));
    formData.append('bank_account_name', String(values.bank_account_name));
    formData.append('bank_account_number', String(values.bank_account_number));
    formData.append('bank_account_holder', String(values.bank_account_holder));
    formData.append('merchant_id', values.merchant_id || '');
    if (values.outlet_category_id && values.outlet_category_id.length > 0) {
      const categories = values.outlet_category_id.join(',');
      formData.append('outlet_categori_id', categories);
    } else {
      formData.append('outlet_categori_id', '');
    }
    formData.append('file', values.image ? values.image.originFileObj : '');

    form.merchant_outlet_operational_hours.map((val: IFormOperationalHour) => {
      formData.append(`outlet_operational_days`, val.day);
      formData.append(`outlet_operational_day_is_open`, String(val.is_open));
      formData.append(`outlet_operational_hour_open`, val.open);
      formData.append(`outlet_operational_hour_close`, val.close);
      return val;
    });

    try {
      if (id) {
        formData.append('is_draft', String(form.is_draft));
        formData.append('is_active', String(form.is_active));

        await dispatch(UUpdateMerchantOutlet(id, formData));
      } else {
        formData.append('is_draft', '1');
        formData.append('is_active', '1');

        const res: any = await dispatch(UCreateMerchantOutlet(formData));
        navigate(`/merchant/outlet/edit/${res.data.id}?step=1`);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onChangeTimeOperational = (id: string, val: string, type: string) => {
    const tempDaySelected: any = form.temp_merchant_outlet_operational_hours.find(
      (detail: any) => detail.day === id,
    );
    if (type === 'open') {
      tempDaySelected.open = val;
      if (moment(val, 'HH:mm:ss') > moment(tempDaySelected.close, 'HH:mm:ss'))
        tempDaySelected.close = val;
    } else {
      tempDaySelected.close = val;
    }

    setForm({
      ...form,
    });
  };

  const onChangeStatusDayOperational = (id: string, flag: boolean) => {
    const tempDaySelected: any = form.temp_merchant_outlet_operational_hours.find(
      (detail: any) => detail.day === id,
    );
    tempDaySelected.is_open = flag ? 1 : 0;

    setForm({
      ...form,
    });
  };

  const onChangeLocation = (value: string) => {
    const location = value.split(',');

    setForm({
      ...form,
      lat: parseFloat(location[0]),
      long: parseFloat(location[1]),
      location: `${location[1]},${location[0]}`,
    });
  };

  const fetchOutletGroup = async () => {
    const payload = {
      sort_by: 'created_at',
      order: 'desc',
      type: 'layanan',
      is_active: 1,
    };
    try {
      const req = await getAllListKategori(payload);
      const { data } = req;
      setListOutletGroup(data);
    } catch (e) {
      setListOutletGroup([]);
    }
  };

  const fetchPartnerPayment = async (search: null | string) => {
    const payload = {
      limit: 10,
      page: 1,
      search,
    };
    try {
      const req = await getPartnerPayment(payload);
      const { data } = req;
      setListPartnerPayment(data);
    } catch (e) {
      setListPartnerPayment([]);
    }
  };

  const handleChangeLayanan = async (outlet_group_id: string) => {
    if (!outlet_group_id) return setListCategoryOutlet([]);

    const tempCategoryOutlet: any = await getAllListKategoriOutlet({
      outlet_group_id,
      is_active: 1,
      sort_by: 'created_at',
      order: 'desc',
    });
    setListCategoryOutlet(tempCategoryOutlet.data);
  };

  return {
    form,
    setForm,
    onChangeOperationalHour,
    handleSubmit,
    handleFilterActiveItem,
    listMerchant,
    listCategoryOutlet,
    listPartnerPayment,
    listOutletGroup,
    listActiveItems,
    listActiveFilterPagination,
    onChangeTimeOperational,
    onChangeStatusDayOperational,
    onChangeLocation,
    handleChangeLayanan,
    showPromptDraft,
    handleShowPromptDraft,
    showPrompt,
    handleShowPrompt,
    isEditValue,
    setIsEditValue,
  };
};
