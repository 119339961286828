import { getlistAllMerchant, getlistAllMerchantOutlet } from '../../data/merchant/merchant.option';
import { setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistAllMerchant = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistAllMerchant(params);
    return res;
  } catch (error) {
    return error;
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetlistAllMerchantOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistAllMerchantOutlet(params);
    return res;
  } catch (error) {
    return error;
  } finally {
    dispatch(setLoadingTable(false));
  }
};
