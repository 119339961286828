import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MARKETING } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistVoucher = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListVoucher = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailVoucher = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/voucher/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createVoucher = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MARKETING}/voucher`, data);
    // SuccessNotification({ description: 'Voucher berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateVoucher = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MARKETING}/voucher/${id}`, data);
    // SuccessNotification({ description: 'Voucher berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteVoucher = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MARKETING}/voucher/${id}`);
    SuccessNotification({ description: 'Voucher berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const uploadImageVoucher = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MARKETING}/voucher/upload`, data);
    SuccessNotification({ description: 'Image Voucher berhasil diupload' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
