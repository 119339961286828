import moment from 'moment';
import { IFormVoucher } from '../model.marketing';

export const listPaymentProvider = [
  { id: '', name: 'Semua Pembayaran' },
  { id: 'ovo', name: 'OVO' },
  { id: 'dana', name: 'Dana' },
  { id: 'linkaja', name: 'Linkaja' },
  { id: 'bca', name: 'BCA' },
  { id: 'mandiri', name: 'Mandiri' },
  { id: 'bni', name: 'BNI' },
  { id: 'bri', name: 'BRI' },
  { id: 'cimb', name: 'CIMB' },
  { id: 'keb hana bank', name: 'Keb Hana Bank' },
];

export const createBodyPayload = (data: any) => {
  let outlet_group = data.outlet_group.map((val: any) => {
    let sub_outlet_groups: Array<any> = [];
    let outlet_categories: Array<any> = [];
    let merchants: Array<any> = [];
    let outlets: Array<any> = [];

    if (val.sub_outlet_groups && val.sub_outlet_groups.length > 0) {
      val.sub_outlet_groups.map((x: any) => {
        sub_outlet_groups.push({
          sub_outlet_group_id: x.value,
          sub_outlet_group_name: x.label,
        });
        return x;
      });
    }

    if (val.outlet_categories && val.outlet_categories.length > 0) {
      val.outlet_categories.map((x: any) => {
        outlet_categories.push({
          outlet_category_id: x.value,
          outlet_category_name: x.label,
        });
        return x;
      });
    }

    if (val.merchants && val.merchants.length > 0) {
      val.merchants.map((x: any) => {
        merchants.push({
          merchant_id: x.value,
          merchant_name: x.label,
        });
        return x;
      });
    }

    if (val.outlets && val.outlets.length > 0) {
      val.outlets.map((x: any) => {
        outlets.push({
          merchant_outlet_id: x.value,
          merchant_outlet_name: x.label,
        });
        return x;
      });
    }

    return {
      outlet_group_id: val.outlet_group_id.value,
      outlet_group_name: val.outlet_group_id.label,
      sub_outlet_groups,
      outlet_categories,
      merchants,
      outlets,
      is_active: 1,
    };
  });

  const payload = {
    coupon_code: data.coupon_code,
    delivery_type: data.delivery_type,
    picture_url: data.imageUrl || data.picture_url,
    terms_and_conditions: data.terms_and_conditions,
    name: data.name,
    valid_from: moment(data.valid[0]).utcOffset(420).format('YYYY-MM-DDT00:00:00Z'),
    valid_until: moment(data.valid[1]).utcOffset(420).format('YYYY-MM-DDT23:59:59Z'),
    voucher_type: data.voucher_type,
    payment_channel: data.payment_channel,
    max_discount: parseInt(data.max_discount),
    min_transaction: parseInt(data.min_transaction),
    limit_per_priod: parseInt(data.limit_per_priod),
    limit_per_day: parseInt(data.limit_per_day),
    limit_per_user: parseInt(data.limit_per_user),
    new_user_only: data.new_user_only,
    is_active: true,
    outlet_group,
    nominal_voucher: parseInt(data.nominal_voucher),
    show_in_percentage: data.show_in_percentage,
  };

  return payload;
};

export const setDetailVoucher = (data: IFormVoucher) => {
  let outlet_group: Array<any> = [];

  if (data.outlet_group && data.outlet_group.length > 0) {
    outlet_group = data.outlet_group.map((val: any) => {
      let sub_outlet_groups: Array<any> = [];
      let outlet_categories: Array<any> = [];
      let merchants: Array<any> = [];
      let outlets: Array<any> = [];

      if (val.sub_outlet_groups && val.sub_outlet_groups.length > 0) {
        val.sub_outlet_groups.map((x: any) => {
          sub_outlet_groups.push({
            key: x.sub_outlet_group_id,
            label: x.sub_outlet_group_name,
            value: x.sub_outlet_group_id,
          });
          return x;
        });
      }

      if (val.outlet_categories && val.outlet_categories.length > 0) {
        val.outlet_categories.map((x: any) => {
          outlet_categories.push({
            key: x.outlet_category_id,
            label: x.outlet_category_name,
            value: x.outlet_category_id,
          });
          return x;
        });
      }

      if (val.merchants && val.merchants.length > 0) {
        val.merchants.map((x: any) => {
          merchants.push({
            key: x.merchant_id,
            label: x.merchant_name,
            value: x.merchant_id,
          });
          return x;
        });
      }

      if (val.outlets && val.outlets.length > 0) {
        val.outlets.map((x: any) => {
          outlets.push({
            key: x.merchant_outlet_id,
            label: x.merchant_outlet_name,
            value: x.merchant_outlet_id,
          });
          return x;
        });
      }

      return {
        outlet_group_id: {
          key: val.outlet_group_id,
          label: val.outlet_group_name,
          value: val.outlet_group_id,
        },
        outlet_group_name: '',
        sub_outlet_groups,
        outlet_categories,
        merchants,
        outlets,
      };
    });
  }

  const detail = {
    id: data.id,
    coupon_code: data.coupon_code,
    delivery_type: data.delivery_type === 'all' ? '' : data.delivery_type,
    picture_url: data.picture_url,
    terms_and_conditions: data.terms_and_conditions,
    name: data.name,
    valid: [moment(data.valid_from), moment(data.valid_until)],
    voucher_type: data.voucher_type,
    payment_channel: data.payment_channel,
    max_discount: data.max_discount,
    min_transaction: data.min_transaction,
    limit_per_priod: data.limit_per_priod,
    limit_per_day: data.limit_per_day,
    limit_per_user: data.limit_per_user,
    new_user_only: data.new_user_only,
    is_visible: data.is_visible,
    outlet_group,
    nominal_voucher: data.nominal_voucher,
    show_in_percentage: data.show_in_percentage,
    is_active: data.is_active,
  };

  return detail;
};

export const checkSelectedFieldSectionMerchant = (
  nameStatus: 'subLayanan' | 'categoryOutlet' | 'merchant' | 'outlet',
  data: any,
) => {
  const { sub_outlet_groups = [], outlet_categories = [], outlets = [] } = data;

  if (nameStatus === 'subLayanan' && sub_outlet_groups && sub_outlet_groups.length > 0) return true;
  else if (nameStatus === 'categoryOutlet' && outlet_categories && outlet_categories.length > 0)
    return true;
  else if (nameStatus === 'outlet' && outlets && outlets.length > 0) return true;

  return false;
};
