import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { setIsDelete } from '../../../redux/item.outlet/item.outlet.action';
import {
  UDeleteItemOutlet,
  UGetlistItemOutlet,
  UUpdateStatusItemOutlet,
} from '../../../usecase/items-outlet/item.outlet.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { checkIsUserOutlet } from '../../merchant/utils/reference.review';
import { IParamsItem } from '../model.items.outlet';

export const useModelItemList = () => {
  const { data } = checkIsUserOutlet();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsItem>({
    page: 1,
    limit: 10,
    q: '',
    merchant_outlet_id: data.id,
    merchant_product_id: '',
    order: 'desc',
    sort_by: 'created_at',
  });
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistItemOutlet(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const payload = {
        is_available: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusItemOutlet(data.id, payload));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteItemOutlet(data.id));
    fetchList(params);
    await dispatch(setIsDelete(true));
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleImport = (data: any) => {
    console.log(data);
  };

  const handleExport = (data: any) => {
    console.log(data);
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleSearch,
    handleDelete,
    handleImport,
    handleExport,
    handleChangePage,
    params,
  };
};
