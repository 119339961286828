import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderItemsOutlet from '../../pages/items.outlet/context/contextprovider.items';

export const itemsOutletRoutes = [
  {
    path: '/items-outlet/item',
    exact: true,
    page: ['items', 'item'],
    component: lazy(() => import('../../pages/items.outlet/item.outlet')),
    layout: Main,
    role: ['Outlet'],
    title: 'Items',
    context: ContextProviderItemsOutlet,
  },
  {
    path: '/items-outlet/item/create',
    exact: true,
    page: ['items', 'item', 'create'],
    component: lazy(() => import('../../pages/items.outlet/create/create.items.item')),
    layout: Main,
    role: ['Outlet'],
    title: 'Items',
    context: ContextProviderItemsOutlet,
  },
  {
    path: '/items-outlet/item/edit/:id',
    exact: true,
    page: ['items', 'item', 'edit'],
    component: lazy(() => import('../../pages/items.outlet/edit/edit.items.item')),
    layout: Main,
    role: ['Outlet'],
    title: 'Items',
    context: ContextProviderItemsOutlet,
  },
];
