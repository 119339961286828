import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { getlistAllMerchant } from '../../../data/merchant/merchant.option';
import {
  UCreateKategoriItem,
  UGetDetailKategoriItem,
  UUpdateKategoriItem,
} from '../../../usecase/items/kategori.item.usecase';
import { formKategoriItem, IFormKategoriItem } from '../model.items';
import { checkIsUserMerchant } from '../utils/refrence.kategori.item';

export const useModelKategoriItemForm = () => {
  const { data, status } = checkIsUserMerchant();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<IFormKategoriItem>(formKategoriItem);
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);

  async function fetchMerchant() {
    const temp: any = await getlistAllMerchant({});
    setMerchantList(temp.data);
  }

  useEffect(() => {
    fetchMerchant();
  }, []);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailKategoriItem(id));
      setForm(temp.data);
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormKategoriItem) => {
    const formData = {
      name: values.name,
      description: values.description,
      merchant_id: values.merchant_id,
      is_active: form.is_active,
    };

    try {
      if (id) {
        await dispatch(UUpdateKategoriItem(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        formData.is_active = 1;
        await dispatch(UCreateKategoriItem(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Kategori Item',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
    listMerchant,
    isUserMerchant: status,
    dataMerchant: data,
  };
};
