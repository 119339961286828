import { dataRawUser, getUser } from '../../../utils/localStorage';

export const checkIsUserMerchant = () => {
  const user = getUser();
  const currentRole: Array<string> = user?.roles || [];
  const isUserMerchant = currentRole.includes('Owner');

  return isUserMerchant
    ? {
        status: true,
        data: dataRawUser()?.merchant,
      }
    : {
        status: false,
        data: '',
      };
};
