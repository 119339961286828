export const workingDays = [
  {
    id: '',
    day: 'Senin',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Selasa',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Rabu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Kamis',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: `Jumat`,
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Sabtu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Minggu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
];

export const initalWorkingDays = [
  {
    id: '',
    day: 'Senin',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Selasa',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Rabu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Kamis',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: `Jumat`,
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Sabtu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
  {
    id: '',
    day: 'Minggu',
    is_open: 0,
    merchant_outlet_id: '',
    open: '00:00:00',
    close: '00:00:00',
  },
] as const;

export const listBank = [
  { id: 'bca', name: 'BCA' },
  { id: 'mandiri', name: 'Mandiri' },
  { id: 'bni', name: 'BNI' },
  { id: 'bri', name: 'BRI' },
  { id: 'cimb', name: 'CIMB' },
  { id: 'keb hana bank', name: 'Keb Hana Bank' },
];
