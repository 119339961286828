import React from 'react';
import { Button, Modal } from 'antd';
import './index.scss';

interface Props {
  text: string;
  callback: any;
  danger?: boolean;
  width?: number;
}

const Content = (callback: any, danger: boolean = false) => (
  <div className="text-center">
    <Button
      danger={danger}
      type="primary"
      onClick={() => {
        callback();
        Modal.destroyAll();
      }}
      style={{ width: '100%', borderRadius: 18 }}>
      TUTUP
    </Button>
  </div>
);

const Title = (text: string) => (
  <div className="text-center">
    <p>
      <b>{text}</b>
    </p>
  </div>
);

export const modalPrompt = (props: Props) =>
  Modal.info({
    content: Content(props.callback, props.danger),
    title: Title(props.text),
    width: props.width || 380,
    icon: false,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    className: 'ant-modal-custom',
    onCancel: props.callback,
  });
