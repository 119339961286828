import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistAllMerchant = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getlistAllMerchantOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-outlet/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
