import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistKategori = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-group`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListKategori = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-group/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailKategori = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-group/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createKategori = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/outlet-group`, data);
    // SuccessNotification({ description: 'Layanan berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateKategori = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/outlet-group/${id}`, data);
    // SuccessNotification({ description: 'Layanan berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusKategori = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(`${SERVICE_MERCHANT}/outlet-group/${id}`, undefined, {
      params,
    });
    // SuccessNotification({ description: 'Layanan berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteKategori = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/outlet-group/${id}`);
    SuccessNotification({ description: 'Outlet berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
