import React, { createContext } from 'react';
import { useModelLayananForm } from './context.layanan.form';
import { useModelLayananList } from './context.layanan.list';
import { useModelSubLayananForm } from './context.sublayanan.form';
import { useModelSubLayananList } from './context.sublayanan.list';

export const ContextKategori = createContext<any>(null);

const ContextProviderKategori: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelLayananForm,
    useModelLayananList,
    useModelSubLayananForm,
    useModelSubLayananList,
  };

  return <ContextKategori.Provider value={context}>{props.children}</ContextKategori.Provider>;
};

export default ContextProviderKategori;
