import React, { createContext } from 'react';
import { useModelKategoriItemList } from './context.kategori.item.list';
import { useModelItemList } from './context.item.list';
import { useModelKategoriItemForm } from './context.kategori.item.form';
import { useModelItemsItemForm } from './context.items.item.form';

export const ContextItems = createContext<any>(null);

const ContextProviderItems: React.FC<{ children: any }> = ({ children }) => {
  const context = {
    useModelKategoriItemList,
    useModelItemList,
    useModelKategoriItemForm,
    useModelItemsItemForm,
  };

  return <ContextItems.Provider value={context}>{children}</ContextItems.Provider>;
};

export default ContextProviderItems;
