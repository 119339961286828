import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { createItem, updateItem } from '../../../data/items/item';
import { getAllListKategoriItem } from '../../../data/items/kategori.item';
import { getlistAllMerchant } from '../../../data/merchant/merchant.option';
import { getAlllistQuantityUnit } from '../../../data/quantity-unit/quantity.option';
import { UGetDetailItem, UUpdateStatusDraftItem } from '../../../usecase/items/item.usecase';
import {
  UCreateItemVariants,
  UDeleteItemVariants,
  UDeleteItemVariantsItem,
  UGetAllListItemVariants,
  UUpdateItemVariants,
} from '../../../usecase/items/item.variants.usecase';
import { isDateNull } from '../../items.outlet/utils/reference';
import { formItemsItem, IFormItemsItem } from '../model.items';
import { createBodyPayloadItem } from '../utils/reference.items';
import { checkIsUserMerchant } from '../utils/refrence.kategori.item';

export const useModelItemsItemForm = () => {
  const { data, status } = checkIsUserMerchant();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormItemsItem>(formItemsItem);
  const [listMerchant, setListMerchant] = useState<Array<any>>([]);
  const [listCategoryItem, setListCategoryItem] = useState<Array<any>>([]);
  const [listQuanityUnit, setListQuanityUnit] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const handleShowPrompt = (flag: boolean) => setShowPrompt(flag);

  const fetchListOption = async (paramsMerchant: any, paramsQuantityUnity: any) => {
    const tempMerchant: any = await getlistAllMerchant(paramsMerchant);
    setListMerchant(tempMerchant.data);

    const tempCategoryQuantityUnit: any = await getAlllistQuantityUnit(paramsQuantityUnity);
    setListQuanityUnit(tempCategoryQuantityUnit.data);
  };

  const handleChangeMerchant = async (paramsCategoryItem: any) => {
    const tempCategoryItem: any = await getAllListKategoriItem(paramsCategoryItem);
    setListCategoryItem(tempCategoryItem.data);
  };

  useEffect(() => {
    fetchListOption({}, {});

    if (status && data.id) {
      handleChangeMerchant({ merchant_id: data.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDetail = async (id: any) => {
    const temp: any = await dispatch(UGetDetailItem(id));
    setForm({
      ...temp.data,
      is_allow_stock: temp.data.is_allow_stock ? true : false,
      is_discount: temp.data.is_discount ? true : false,
      discount_start_date: isDateNull(temp.data.discount_start_date),
      discount_end_date: isDateNull(temp.data.discount_end_date),
    });

    if (!status) {
      handleChangeMerchant({ merchant_id: temp.data.merchant_id });
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmitItem = async (
    values: any,
    type: 'create' | 'edit' | 'final',
    step?: number,
  ) => {
    setLoading(true);
    const formData = new FormData();
    const payload = createBodyPayloadItem(values, form);

    formData.append('name', payload.name);
    formData.append('description', payload.description);
    formData.append('product_base_price', String(payload.product_base_price));
    formData.append('merchant_product_category_id', String(payload.merchant_product_category_id));
    formData.append('merchant_id', String(payload.merchant_id));
    formData.append('stock_unit_id', String(payload.stock_unit_id));
    formData.append('is_allow_stock', payload.is_allow_stock ? '1' : '0');
    formData.append('file', values.originFileObj || values.imageUrl);
    formData.append('is_active', payload.is_active);
    formData.append('is_draft', payload.is_draft);

    try {
      if (!id && type === 'create') {
        const res = await createItem(formData);

        navigate(`/items/item/edit/${res.data.id}?step=1`);
      } else {
        if (step === 2) {
          formData.append('is_discount', payload.is_discount ? '1' : '0');
          formData.append('discount_price', String(payload.discount_price));
          formData.append(
            'show_discount_in_percentage',
            String(payload.show_discount_in_percentage),
          );
          if (payload.discount_start_date)
            formData.append(
              'discount_start_date',
              moment(payload.discount_start_date).utcOffset(420).format('YYYY-MM-DDT00:00:00Z'),
            );
          if (payload.discount_end_date)
            formData.append(
              'discount_end_date',
              moment(payload.discount_end_date).utcOffset(420).format('YYYY-MM-DDT23:59:59Z'),
            );
        }

        await updateItem(id, formData);
        await fetchDetail(id);

        if (type === 'final') {
          modalSuccess({
            title: 'Kamu berhasil menambahkan Item',
            callback: () => navigate('/items/item'),
          });
        }
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitVarian = async (values: Array<any>) => {
    try {
      for (let index = 0; index < values.length; index++) {
        const payload = values[index];
        if (payload.id) {
          await dispatch(UUpdateItemVariants(payload.id, payload));
        } else {
          await dispatch(UCreateItemVariants({ ...payload, merchant_product_id: id }));
        }
      }
      await dispatch(UUpdateStatusDraftItem(id, { is_draft: '0' }));

      modalSuccess({
        title: 'Kamu berhasil menambahkan Item',
        callback: () => navigate('/items/item'),
      });
    } catch (error) {
      Promise.reject(error);
    }
  };

  const handleDeleteVarian = async (values: any) => {
    try {
      if (!values.id) return true;

      await dispatch(UDeleteItemVariants(values.id));
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleDeleteVarianItem = async (values: any) => {
    try {
      if (!values.id) return true;

      await dispatch(UDeleteItemVariantsItem(values.id));
      return true;
    } catch (error) {
      return false;
    }
  };

  const getDetailVarian = async (params: any) => {
    if (!id) return [];

    const newParams = {
      ...params,
      merchant_product_ids: id,
    };
    const res: any = await dispatch(UGetAllListItemVariants(newParams));

    return res.data;
  };

  return {
    form,
    setForm,
    handleSubmitItem,
    handleSubmitVarian,
    listMerchant,
    listCategoryItem,
    loading,
    listQuanityUnit,
    getDetailVarian,
    handleDeleteVarian,
    handleDeleteVarianItem,
    handleChangeMerchant,
    isUserMerchant: status,
    dataMerchant: data,
    showPrompt,
    handleShowPrompt,
  };
};
