import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { getAllListKategoriItem } from '../../../data/items/kategori.item';
import { UDeleteItem, UGetlistItem, UUpdateStatusItem } from '../../../usecase/items/item.usecase';
import { UGetlistAllMerchant } from '../../../usecase/merchant/merchant.option.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsItem } from '../model.items';
import { checkIsUserMerchant } from '../utils/refrence.kategori.item';

export const useModelItemList = () => {
  const { data, status } = checkIsUserMerchant();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsItem>({
    page: 1,
    limit: 10,
    q: '',
    merchant_id: status ? data.id : '',
    merchant_outlet_id: '',
    merchant_product_category_id: '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);
  const [listMerchantOutlet, setMerchantListOutlet] = useState<Array<any>>([]);
  const [listCategoryItem, setCategoryItem] = useState<Array<any>>([]);
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchOption() {
    const temp: any = await dispatch(UGetlistAllMerchant({}));
    setMerchantList(temp.data);
  }

  const handleChangeMerchant = async (merchant_id: string) => {
    setMerchantListOutlet([]);

    if (!merchant_id) return setCategoryItem([]);
    const tempCategory: any = await getAllListKategoriItem({ merchant_id });
    setCategoryItem(tempCategory.data);
  };

  useEffect(() => {
    fetchOption();

    if (params.merchant_id) {
      handleChangeMerchant(params.merchant_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistItem(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const payload = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusItem(data.id, payload));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteItem(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleFilter = (data: any) => {
    setParams({
      ...params,
      page: 1,
      merchant_id: data.merchant_id,
      merchant_product_category_id: data.merchant_product_category_id,
    });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleExport = (data: any) => {
    console.log(data);
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleSearch,
    handleDelete,
    handleExport,
    handleFilter,
    handleChangePage,
    params,
    listMerchant,
    listMerchantOutlet,
    listCategoryItem,
    handleChangeMerchant,
    isUserMerchant: status,
    dataMerchant: data,
  };
};
