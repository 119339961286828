import { useEffect, useState } from 'react';

export const useModelMarketingForm = () => {
  const [form, setForm] = useState({});

  useEffect(() => {}, []);

  return {
    form,
    setForm,
  };
};
