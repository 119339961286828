import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getAllListKategori } from '../../../data/kategori/kategori';
import {
  UDeleteVoucher,
  UGetlistVoucher,
  UUpdateVoucher,
} from '../../../usecase/marketing/voucher.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsVoucher } from '../model.marketing';
import { getDetailVoucher } from '../../../data/marketing/voucher';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';

export const useModelVoucherList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsVoucher>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    valid_from: '',
    valid_until: '',
    outlet_group_ids: '',
    outlet_sub_group_ids: '',
  });
  const [listLayanan, setListLayanan] = useState<Array<any>>([]);
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchLayanan() {
    const temp: any = await getAllListKategori({ type: '' });
    setListLayanan(temp.data || []);
  }

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistVoucher(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchLayanan();
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const res: any = await getDetailVoucher(data.id);

      const formData = {
        ...res.data,
        is_active: status,
      };
      await dispatch(UUpdateVoucher(data.id, formData));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteVoucher(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleFilter = (data: any) => {
    let filter = {
      valid_from: moment(data.periode[0]).format('YYYY-MM-DD'),
      valid_until: moment(data.periode[1]).format('YYYY-MM-DD'),
    } as IParamsVoucher;

    const layanan = listLayanan.find((val) => val.id === data.outlet_group_ids);

    if (layanan.parent.id) {
      filter = {
        ...filter,
        outlet_sub_group_ids: data.outlet_group_ids,
        outlet_group_ids: '',
      };
    } else {
      filter = {
        ...filter,
        outlet_sub_group_ids: '',
        outlet_group_ids: data.outlet_group_ids,
      };
    }

    setParams({ ...params, ...filter, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleExport = (data: any) => {
    console.log(data);
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    listLayanan,
    handleDelete,
    handleSearch,
    handleFilter,
    handleChangePage,
    handleExport,
    handleSetStatus,
  };
};
