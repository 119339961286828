import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIp } from '../../../data/auth';
import { getlistNotification } from '../../../data/notification/notification';
import { setGroupDataNotif } from '../../../pages/notification/config/utils';
import { IParamsNotification } from '../../../pages/notification/model.notification';
import { setUpdateNotification } from '../../../redux/notification/notification.actions';
import { onMessageListener, requestForToken } from '../../../services/firebase';
import {
  UGetDetailNotification,
  URegisterTokenNotification,
} from '../../../usecase/notification/notification.usecase';
import { browserName } from '../../../utils/device';
import { getUser, setUpdateFcmToken } from '../../../utils/localStorage';
import { checkMenuRoles } from '../../Sider/utils';
import { enumTypeNotification } from '../config/enum';
import { openNotificationWithIcon } from '../section/CustomNotification';

export const useNotificationViewModel = ({ updateNotification }: any) => {
  const currentUser = getUser();
  const userRoles = checkMenuRoles(currentUser?.roles);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<{ count: number; list: Array<any> }>({
    count: 0,
    list: [],
  });
  const params: IParamsNotification = {
    page: 1,
    limit: 5,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    is_read: '',
    destination_id: `user-${userRoles.toLowerCase()}`,
  };

  const fetchCount = async () =>
    await getlistNotification({
      ...params,
      limit: 1,
      is_read: false,
      destination_id: `user-${userRoles.toLowerCase()}`,
    });

  const fetchData = async () => {
    try {
      const resCount: any = await fetchCount();
      const resList: any = await getlistNotification(params);

      setData({
        count: resCount.meta.total_data,
        list: setGroupDataNotif(resList.data),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendTokenFCM = async () => {
    try {
      const token: any = await requestForToken();
      const { ip }: any = await getIp();

      const payload = {
        app_version: 2,
        device_name: browserName.name,
        push_notif_token: token,
        device_type: 'webpush',
        device_os: 0,
        device_model: '',
        ip,
      };

      await dispatch(URegisterTokenNotification(payload));

      setUpdateFcmToken(token);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();

    handleSendTokenFCM();
  }, []);

  useEffect(() => {
    if (updateNotification) updateCountBadge();
  }, [updateNotification]);

  const handleNotification = () =>
    openNotificationWithIcon({
      title: 'Pesanan Masuk',
      description: 'Detail Pesanan Masuk',
      type: 'info',
    });

  onMessageListener(fetchData);

  const handleClickNotif = async (value: any) => {
    await dispatch(UGetDetailNotification(value.id));
    const resCount: any = await fetchCount();
    setData({
      ...data,
      count: resCount.meta.total_data,
    });

    if (value.click_action === enumTypeNotification.CAMPAIGN) {
      navigate(`/marketing/campaign-blast/detail/${value.additional_data.data.id}`);
    } else if (value.click_action === enumTypeNotification.ORDER) {
      navigate(`/transaksi/transaksi-masuk/detail/${value.additional_data.data.id}`);
    }
  };

  const updateCountBadge = async () => {
    dispatch(setUpdateNotification(false));

    const resCount: any = await fetchCount();

    setData({
      ...data,
      count: resCount.meta.total_data,
    });
  };

  return {
    navigate,
    handleNotification,
    data,
    handleClickNotif,
  };
};
