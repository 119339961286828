import {
  createVoucher,
  deleteVoucher,
  getAllListVoucher,
  getDetailVoucher,
  getlistVoucher,
  updateVoucher,
  uploadImageVoucher,
} from '../../data/marketing/voucher';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistVoucher = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistVoucher(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListVoucher = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListVoucher(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailVoucher = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailVoucher(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateVoucher = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createVoucher(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateVoucher = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateVoucher(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteVoucher = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteVoucher(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UUploadImageVoucher = (file: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await uploadImageVoucher(file);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
