import React from 'react';
import { Button, Modal } from 'antd';
import './index.scss';

interface Props {
  title: string;
  callback: any;
}

const Content = (callback: any) => (
  <div className="text-center">
    <Button
      type="primary"
      onClick={() => {
        callback();
        Modal.destroyAll();
      }}
      style={{ width: '100%', borderRadius: 18 }}>
      TUTUP
    </Button>
  </div>
);

const Title = (text: string) => (
  <div className="text-center">
    <p>
      <b>
        Yuhuu!
        <br />
        {text}
      </b>
    </p>
  </div>
);

export const modalSuccess = (props: Props) =>
  Modal.info({
    content: Content(props.callback),
    title: Title(props.title),
    width: 300,
    icon: false,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    className: 'ant-modal-custom',
    onCancel: props.callback,
  });
