import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { SERVICE_NOTIFICATION } from '../../config/keys';
import httpService from '../../services/http.service';

export const registerTokenNotification = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_NOTIFICATION}/client`, data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getlistNotification = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_NOTIFICATION}/`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailNotification = async (id: string) => {
  try {
    const res = await httpService.get(`${SERVICE_NOTIFICATION}/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateAllRead = async () => {
  try {
    const res = await httpService.put(`${SERVICE_NOTIFICATION}/me/is-read`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
