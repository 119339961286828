import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { checkMenuRoles } from '../../components/Sider/utils';
import { SERVICE_ORDER } from '../../config/keys';
import httpService from '../../services/http.service';
import { getUser } from '../../utils/localStorage';
import { enumRole } from '../../utils/pages';

const currentUser = getUser();
const userRoles = checkMenuRoles(currentUser?.roles);
const accessService = userRoles === enumRole.SUPERUSER ? 'admin' : 'outlet';

export const getlistTransaksi = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/order`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailTransaksi = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/order/id/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getPartnerPayment = async (params: {
  limit: number;
  page: number;
  search: string | null;
}) => {
  try {
    return await httpService.get(
      `${SERVICE_ORDER}/${accessService === 'outlet' ? 'user' : accessService}/payment/partner`,
      {
        params,
      },
    );
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getPaymentProduct = async (params: {
  limit: number;
  page: number;
  search: string | null;
}) => {
  try {
    return await httpService.get(
      `${SERVICE_ORDER}/${accessService === 'outlet' ? 'user' : accessService}/payment/product`,
      {
        params,
      },
    );
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const exportlistTransaksi = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_ORDER}/${accessService}/order/export`, { params });

    return res.data;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
