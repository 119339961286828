import flagAll from '../../../assets/icons/flag-all.svg';
import flagMerchant from '../../../assets/icons/flag-merchant.svg';
import { getDetailKaryawanMap } from '../../../data/user-management/karyawan';
import { getDetailMerchantOutlet } from '../../../data/merchant/outlet';
import { setUpdateUser } from '../../../utils/localStorage';
import { ICurrent } from './Box.Profile';

export const getFlagRole = (current: ICurrent) => {
  if (current.role === 'Superuser' || current.role === 'Superadmin') return '';

  if (current.status === 'Buka') {
    return flagAll;
  }

  return flagMerchant;
};

export const checkDetailProfile = async (user: any) => {
  const currentRole: Array<string> = user?.roles || [];

  if (currentRole.includes('Superadmin'))
    return { box: false, name: user.name, status: '', role: 'Superuser' };
  if (currentRole.includes('Superuser'))
    return { box: false, name: user.name, status: '', role: 'Superuser' };

  try {
    const {
      data: { merchant, outlet_employe_mapping },
    } = await getDetailKaryawanMap(user.id);
    setUpdateUser(user, { merchant });

    if (currentRole.includes('Owner'))
      return {
        box: true,
        name: merchant.name,
        status: merchant.is_active === 1 ? 'Buka' : 'Tutup',
        role: 'Owner',
      };

    let resOutlet = null;

    if (outlet_employe_mapping.merchant_outlet_id) {
      resOutlet = await getDetailMerchantOutlet(outlet_employe_mapping.merchant_outlet_id);
      setUpdateUser(user, { outlet: resOutlet.data });
    }

    if (currentRole.includes('Outlet')) {
      return {
        box: true,
        name: !resOutlet ? user.name : resOutlet.data.name,
        status: !resOutlet
          ? 'Tutup'
          : resOutlet.data.is_active && resOutlet.data.is_open === 1
          ? 'Buka'
          : 'Tutup',
        role: 'Outlet',
      };
    }
  } catch (error) {
    console.log('Error Mapping User');
  }

  return { box: false, name: '', status: '', role: '' };
};
