import {
  createItemVariants,
  deleteItemVariants,
  deleteItemVariantsItem,
  getAllListItemVariants,
  updateItemVariants,
} from '../../data/items/item.variants';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetAllListItemVariants = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getAllListItemVariants(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateItemVariants = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createItemVariants(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateItemVariants = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateItemVariants(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteItemVariants = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteItemVariants(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UDeleteItemVariantsItem = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteItemVariantsItem(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
