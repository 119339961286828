import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { UGetAllListKategori } from '../../../usecase/kategori/kategori.usecase';
import {
  UDeleteKategoriOutlet,
  UGetlistKategoriOutlet,
  UUpdateStatusKategoriOutlet,
} from '../../../usecase/merchant/kategori.outlet.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsKategoriOutlet } from '../model.merchant';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

interface IFilter {
  layanan_id: string;
  sub_layanan_id: string;
}

export const useModelKategoriOutletList = () => {
  const dispatch = useDispatch();
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsKategoriOutlet>({
    page: 1,
    limit: 10,
    q: '',
    outlet_group_id: '',
    layanan_id: '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const debouncedParams = useDebounce(params, 300);
  const [listLayanan, setLayananList] = useState<Array<any>>([]);
  const [listSubLayanan, setSubLayananList] = useState<Array<any>>([]);

  async function fetchList(params: any) {
    delete params.layanan_id;
    const temp: any = await dispatch(UGetlistKategoriOutlet(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  async function fetchLayanan(parent_id?: string) {
    if (parent_id) {
      const temp: any = await dispatch(UGetAllListKategori({ type: 'sub-layanan', parent_id }));
      setSubLayananList(temp.data || []);
    } else {
      const temp: any = await dispatch(UGetAllListKategori({ type: 'layanan' }));
      setLayananList(temp.data || []);
    }
  }

  useEffect(() => {
    fetchLayanan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const formParams = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusKategoriOutlet(data.id, formParams));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteKategoriOutlet(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleFilter = (data: IFilter) => {
    setParams({ ...params, outlet_group_id: data.sub_layanan_id || data.layanan_id, page: 1 });
  };

  const handleChangeLayanan = (e: string) => fetchLayanan(e);

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleDelete,
    params,
    handleSearch,
    handleChangePage,
    handleFilter,
    listLayanan,
    listSubLayanan,
    handleChangeLayanan,
  };
};
