import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderItems from '../../pages/items/context/contextprovider.items';

export const itemsRoutes = [
  {
    path: '/items/kategori-item',
    exact: true,
    page: ['items', 'kategori item'],
    component: lazy(() => import('../../pages/items/kategori.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/kategori-item/create',
    exact: true,
    page: ['items', 'kategori item', 'create'],
    component: lazy(() => import('../../pages/items/create/create.kategori.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/kategori-item/edit/:id',
    exact: true,
    page: ['items', 'kategori item', 'edit'],
    component: lazy(() => import('../../pages/items/edit/edit.kategori.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/item',
    exact: true,
    page: ['items', 'item'],
    component: lazy(() => import('../../pages/items/item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/item/create',
    exact: true,
    page: ['items', 'item', 'create'],
    component: lazy(() => import('../../pages/items/create/create.items.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/item/edit/:id',
    exact: true,
    page: ['items', 'item', 'edit'],
    component: lazy(() => import('../../pages/items/edit/edit.items.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
  {
    path: '/items/item/import',
    exact: true,
    page: ['items', 'item', 'import'],
    component: lazy(() => import('../../pages/items/import/import.items.item')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Items',
    context: ContextProviderItems,
  },
];
