import React, { createContext } from 'react';
import { useModelFormProfile } from './context.profile.form';
import { useModelFormChangePassword } from './context.change.password.form';

export const ContextProfile = createContext<any>(null);

const ContextProviderProfile: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelFormProfile,
    useModelFormChangePassword,
  };

  return <ContextProfile.Provider value={context}>{props.children}</ContextProfile.Provider>;
};

export default ContextProviderProfile;
