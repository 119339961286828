import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  UDeleteMerchantOutlet,
  UGetlistMerchantOutlet,
  USelectMerchantOutlet,
  UUpdateStatusMerchantOutlet,
} from '../../../usecase/merchant/outlet.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsMerchantOutlet } from '../model.merchant';
import { modalHistory } from '../../../components/Modal/ModalActionHistory';
import { getAllListMerchantOutlet } from '../../../data/merchant/outlet';
import { getAllListMerchant } from '../../../data/merchant/merchant';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelOutletList = () => {
  const { data, status } = checkIsUserMerchant();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsMerchantOutlet>({
    page: 1,
    limit: 10,
    q: '',
    ids: '',
    merchant_ids: status ? data.id : '',
    outlet_category_ids: '',
    outlet_group_ids: '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);
  const [listOutlet, setListOutlet] = useState<Array<any>>([]);
  const [listMerchant, setListMerchant] = useState<Array<any>>([]);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistMerchantOutlet(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  async function fetchOutlet(params: any) {
    const temp: any = await getAllListMerchantOutlet(params);
    setListOutlet(temp.data);
  }

  async function fetchMerchant(params: any) {
    const temp: any = await getAllListMerchant(params);
    setListMerchant(temp.data);
  }

  const handleChangeMerchant = async (merchant_ids: string) => {
    if (!merchant_ids) return setListOutlet([]);
    fetchOutlet({ merchant_ids });
  };

  useEffect(() => {
    fetchMerchant({});
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const formParams = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusMerchantOutlet(data.id, formParams));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDuplicate = (data: any) => {
    console.log(data);
  };

  const handleImport = (data: any) => {
    console.log(data);
  };

  const handleExport = (data: any) => {
    console.log(data);
  };

  const handleFilter = (data: any) => {
    setParams({ ...params, ...data, page: 1 });
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteMerchantOutlet(data.id));
    fetchList(params);
  };

  const getOutletSelect = async (
    outlet_group_id: string,
    search: string | null,
    outlet_category_id: string | null,
  ) => {
    const res: any = await dispatch(
      USelectMerchantOutlet(outlet_group_id, search, outlet_category_id),
    );
    return res?.data;
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleHistory = (data: any) => {
    modalHistory({
      width: 440,
      data,
      title: <h4>Informasi</h4>,
    });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleDelete,
    handleDuplicate,
    handleHistory,
    handleImport,
    handleExport,
    params,
    handleFilter,
    handleSearch,
    handleChangePage,
    getOutletSelect,
    listOutlet,
    listMerchant,
    handleChangeMerchant,
    isUserMerchant: status,
    dataMerchant: data,
  };
};
