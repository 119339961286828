import { ErrorNotification } from '../../components/Notification/CustomNotification';
import {
  createMerchantOutlet,
  deleteMerchantOutlet,
  getAllListMerchantOutlet,
  getDetailMerchantOutlet,
  getlistMerchantOutlet,
  updateMerchantOutlet,
  updateStatusMerchantOutlet,
} from '../../data/merchant/outlet';
import { getlistKaryawanMapping } from '../../data/user-management/karyawan.mapping';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistMerchantOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistMerchantOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListMerchantOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListMerchantOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailMerchantOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailMerchantOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateMerchantOutlet = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createMerchantOutlet(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateMerchantOutlet = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateMerchantOutlet(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateStatusMerchantOutlet = (id: string, params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusMerchantOutlet(id, params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteMerchantOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteMerchantOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const USelectMerchantOutlet =
  (outlet_group_id: string, search: string | null, outlet_category_id: string | null) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingTable(true));
      return await getAllListMerchantOutlet({
        outlet_group_ids: outlet_group_id,
        search,
        outlet_category_ids: outlet_category_id,
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      dispatch(setLoadingTable(false));
    }
  };

export const UGetListAdminMerchantOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getlistKaryawanMapping(id);
    return res;
  } catch (error) {
    return ErrorNotification(error);
  } finally {
    dispatch(setLoading(false));
  }
};
