import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_CLIENT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistRole = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/role`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListRole = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/role/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailRole = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/role/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createRole = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_CLIENT}/role`, data);
    SuccessNotification({ description: 'Role berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateRole = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_CLIENT}/role/${id}`, data);
    SuccessNotification({ description: 'Role berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteRole = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_CLIENT}/role/${id}`);
    SuccessNotification({ description: 'Role berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
