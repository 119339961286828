import _ from 'lodash';
import moment from 'moment';

const checkDate = (date: any) => {
  const REFERENCE = moment(date);
  const TODAY = REFERENCE.clone().startOf('day');
  const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

  if (TODAY) return 'Hari ini';
  if (YESTERDAY) return 'Kemarin';

  return moment(date.sent_at).locale('id').format('DD MMMM YYYY');
};

export const setGroupDataNotif = (data: Array<any>) => {
  const temp = data.map((val: any) => ({
    ...val,
    converted_date: checkDate(val.sent_at),
  }));

  const grouped = _.mapValues(_.groupBy(temp, 'converted_date'), (x: any) =>
    x.map((y: any) => _.omit(y, 'converted_date')),
  );

  return Object.entries(grouped);
};
