import {
  createItemOutlet,
  deleteItemOutlet,
  getAllListItemOutlet,
  getDetailItemOutlet,
  getlistItemOutlet,
  updateItemOutlet,
  updateStatusItemOutlet,
} from '../../data/items-outlet/item';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistItemOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistItemOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListItemOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListItemOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailItemOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailItemOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateItemOutlet = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createItemOutlet(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateItemOutlet = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateItemOutlet(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateStatusItemOutlet = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusItemOutlet(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteItemOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteItemOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
