import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistMerchant = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListMerchant = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailMerchant = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createMerchant = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}`, data);
    // SuccessNotification({ description: 'Merchant berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateMerchant = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/${id}`, data);
    // SuccessNotification({ description: 'Merchant berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusMerchant = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(`${SERVICE_MERCHANT}/${id}`, undefined, { params });
    // SuccessNotification({ description: 'Merchant berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteMerchant = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/${id}`);
    SuccessNotification({ description: 'Merchant berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
