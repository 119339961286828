import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalPrompt } from '../../../components/Modal/ModalPrompt';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  getlistAllMerchant,
  getlistAllMerchantOutlet,
} from '../../../data/merchant/merchant.option';
import {
  UCreateKaryawan,
  UGetDetailKaryawan,
  UGetIsKaryawanLogin,
  UUpdateKaryawan,
} from '../../../usecase/user-management/karyawan.usecase';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';
import { formKaryawan, IFormKaryawan } from '../model.usermanagement';

export const useModelKaryawanForm = () => {
  const { data, status } = checkIsUserMerchant();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormKaryawan>(formKaryawan);
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);
  const [listOutlet, setOutletList] = useState<Array<any>>([]);

  async function fetchMerchant(params: any) {
    const tempMerchant: any = await getlistAllMerchant(params);
    setMerchantList(tempMerchant.data);
  }

  async function fetchOutlet(params: any) {
    const tempOutlet: any = await getlistAllMerchantOutlet(params);
    setOutletList(tempOutlet.data);
  }

  useEffect(() => {
    fetchMerchant({ ids: status ? data.id : undefined });

    if (status) {
      fetchOutlet({ merchant_ids: data.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailKaryawan(id));
      await fetchOutlet({ merchant_ids: temp.data.merchant_id });

      setForm({
        ...temp.data,
        sex: String(temp.data.sex || 0),
        date_of_birth: temp.data.date_of_birth ? moment(temp.data.date_of_birth) : null,
        join_date: moment(temp.data.join_date, 'YYYY-MM-DD HH:mm:ss'),
        address: temp.data.address || '',
        employment: temp.data.employment || '',
        merchant_outlet_id: temp.data.outlet_employe_mapping.merchant_outlet_id,
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormKaryawan) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('phone_number', values.phone_number);
    formData.append('email', values.email);
    formData.append('address', values.address);
    formData.append('sex', String(values.sex));
    formData.append('birth_place', values.birth_place);
    formData.append(
      'date_of_birth',
      values.date_of_birth ? moment(values.date_of_birth).format('YYYY-MM-DD') : '',
    );
    formData.append(
      'join_date',
      values.join_date ? moment(values.join_date).format('YYYY-MM-DD HH:mm:ss') : '',
    );
    formData.append('employment', values.employment);
    formData.append('merchant_id', values.merchant_id || '');
    formData.append('is_active_account', String(values.is_active_account));

    if (values.role) formData.append('role', values.role || '');
    if (values.password) formData.append('password', String(values.password));
    if (form.role === 'Outlet' && !values.role) {
      formData.append('merchant_outlet_id', form.merchant_outlet_id || '');
    } else if (values.role && values.role === 'Outlet') {
      formData.append('merchant_outlet_id', values.merchant_outlet_id || '');
    }

    try {
      if (id) {
        const isLogin: any = await dispatch(UGetIsKaryawanLogin(id));
        if (isLogin) {
          return modalPrompt({
            text: 'Anda tidak dapat melakukan perubahan data, karena akun ini sedang login ke aplikasi.',
            danger: true,
            callback: () => {},
            width: 420,
          });
        }

        await dispatch(UUpdateKaryawan(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        await dispatch(UCreateKaryawan(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data karyawan',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeMerchant = (merchant_ids: string) => fetchOutlet({ merchant_ids });

  return {
    form,
    setForm,
    handleSubmit,
    listMerchant,
    listOutlet,
    handleChangeMerchant,
    isUserMerchant: status,
    dataMerchant: data,
  };
};
