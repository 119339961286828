import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UCreateVoucher,
  UGetDetailVoucher,
  UUpdateVoucher,
  UUploadImageVoucher,
} from '../../../usecase/marketing/voucher.usecase';
import { formVoucher, IFormVoucher } from '../model.marketing';
import { createBodyPayload, setDetailVoucher } from '../utils/references.voucher';

export const useModelVoucherForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormVoucher>(formVoucher);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailVoucher(id));
      const convertData = setDetailVoucher(temp.data);
      setForm(convertData);
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: any) => {
    const payload = createBodyPayload(values);

    try {
      if (id) {
        await dispatch(UUpdateVoucher(id, { ...payload, is_active: form.is_active }));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        await dispatch(UCreateVoucher(payload));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Voucher',
          callback: () => navigate(-1),
        });
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  const handleUploadImage = async (data: any) => {
    const formData = new FormData();
    formData.append('files', data);

    try {
      const res: any = await dispatch(UUploadImageVoucher(formData));

      return res.data[0];
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
    handleUploadImage,
  };
};
