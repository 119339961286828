import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UDeleteRole, UGetlistRole } from '../../../usecase/user-management/role.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsRole } from '../model.usermanagement';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelRoleList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsRole>({
    page: 1,
    limit: 10,
    q: '',
    orderBy: 'name DESC',
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistRole(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteRole(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleDelete,
    params,
    handleSearch,
    handleChangePage,
  };
};
