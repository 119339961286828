import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';

interface ICurrency {
  value: string | number;
  type?: string;
  thousandSeparator?: string;
  decimal?: boolean;
  convertMinus?: boolean;
}

const passwordTester = '^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$';

const currency = (props: ICurrency) => {
  const {
    value,
    type = 'Rp',
    thousandSeparator = '.',
    decimal = false,
    convertMinus = false,
  } = props;

  let formattedNumber = Math.round(Number(value))
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousandSeparator}`);

  if (decimal) {
    const toFixedNum = parseFloat(String(value)).toFixed(2); // set two decimal places
    const decimalSplit = toFixedNum ? toFixedNum.split('.') : ['0', '00'];
    decimalSplit[0] = decimalSplit[0]
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousandSeparator}`);
    formattedNumber = decimalSplit.join(thousandSeparator === ',' ? '.' : ',');
  }

  if (convertMinus && parseFloat(String(value)) < 0) {
    const numberWithoutMinus = formattedNumber.toString().replace('-', '');
    return type.length > 0 ? `( ${type} ${numberWithoutMinus} )` : `( ${numberWithoutMinus} )`;
  }

  return type.length > 0 ? `${type} ${formattedNumber}` : formattedNumber;
};

const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const base_url = () => window.location.origin;

const subStringText = (text: string = '', limit: number = 30) => {
  if (text.length > limit) {
    const result = text.substring(0, limit);

    return result.concat('...');
  }

  return text;
};

const isPasswordStrongth = (text: string, exp: string = passwordTester) => {
  const regex = new RegExp(exp);

  return regex.test(text);
};

const convertHtmlToString = (markup: string) => {
  const blocksFromHTML: any = convertFromHTML(markup);
  const state = ContentState.createFromBlockArray(blocksFromHTML);
  const editorState = EditorState.createWithContent(state);

  return convertToRaw(editorState.getCurrentContent()).blocks[0].text;
};

//convert number to currency
const convertToCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });

  return formatter.format(value);
};

//create pluck from array object
const pluck = (array: any, key: string) => {
  return array.map((item: any) => item[key]);
};

const toDataOption = (data: Array<any> = [], label: string = 'name', id: string = 'id') =>
  data.map((val) => ({
    id: val[id],
    name: val[label],
  }));

export {
  currency,
  convertToCurrency,
  getBase64,
  base_url,
  subStringText,
  isPasswordStrongth,
  convertHtmlToString,
  pluck,
  toDataOption,
};
