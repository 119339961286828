import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistMerchantOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-outlet`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListMerchantOutlet = async (params: any) => {
  try {
    return await httpService.get(`${SERVICE_MERCHANT}/merchant-outlet/all`, { params });
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailMerchantOutlet = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-outlet/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createMerchantOutlet = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/merchant-outlet`, data);
    // SuccessNotification({ description: 'Merchant berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateMerchantOutlet = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/merchant-outlet/${id}`, data);
    // SuccessNotification({ description: 'Merchant berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const patchMerchantOutletActive = async (id: string, activate: number) => {
  try {
    return await httpService.patch(
      `${SERVICE_MERCHANT}/merchant-outlet/${id}?is_active=${activate}`,
    );
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
export const patchMerchantOutletDraft = async (id: string, activate: number) => {
  try {
    return await httpService.patch(
      `${SERVICE_MERCHANT}/merchant-outlet/${id}?is_draft=${activate}`,
    );
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusMerchantOutlet = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(`${SERVICE_MERCHANT}/merchant-outlet/${id}`, undefined, {
      params,
    });
    // SuccessNotification({ description: 'Layanan berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteMerchantOutlet = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/merchant-outlet/${id}`);
    SuccessNotification({ description: 'Layanan berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
