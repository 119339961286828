import './index.scss';
import { ReactComponent as IconLoading } from '../../assets/icons/icon_loading.svg';

const Loading: React.FC = () => {
  return (
    <div className="center-content">
      <div className="loading-screen">
        <IconLoading className="rotating" />

        <h6 className="text-loading">Loading...</h6>
      </div>
    </div>
  );
};

export default Loading;
