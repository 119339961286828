import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderMarketing from '../../pages/marketing/context/context.provider.marketing';

export const marketingRoutes = [
  {
    path: '/marketing/voucher',
    exact: true,
    page: ['marketing', 'voucher'],
    component: lazy(() => import('../../pages/marketing/voucher')),
    layout: Main,
    role: ['Superuser'],
    title: 'Voucher',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/voucher/create',
    exact: true,
    page: ['marketing', 'voucher', 'create'],
    component: lazy(() => import('../../pages/marketing/create/create.voucher')),
    layout: Main,
    role: ['Superuser'],
    title: 'Voucher',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/voucher/detail/:id',
    exact: true,
    page: ['marketing', 'voucher', 'detail'],
    component: lazy(() => import('../../pages/marketing/detail/detail.transaksi.voucher')),
    layout: Main,
    role: ['Superuser'],
    title: 'Voucher',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/voucher/edit/:id',
    exact: true,
    page: ['marketing', 'voucher', 'edit'],
    component: lazy(() => import('../../pages/marketing/edit/edit.voucher')),
    layout: Main,
    role: ['Superuser'],
    title: 'Voucher',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/banner',
    exact: true,
    page: ['marketing', 'banner'],
    component: lazy(() => import('../../pages/marketing/banner')),
    layout: Main,
    role: ['Superuser'],
    title: 'Banner',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/banner/create',
    exact: true,
    page: ['marketing', 'banner', 'create'],
    component: lazy(() => import('../../pages/marketing/create/create.banner')),
    layout: Main,
    role: ['Superuser'],
    title: 'Banner',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/banner/edit/:id',
    exact: true,
    page: ['marketing', 'banner', 'edit'],
    component: lazy(() => import('../../pages/marketing/edit/edit.banner')),
    layout: Main,
    role: ['Superuser'],
    title: 'Banner',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/campaign-blast',
    exact: true,
    page: ['marketing', 'campaign-blast'],
    component: lazy(() => import('../../pages/marketing/campaign.blast')),
    layout: Main,
    role: ['Superuser'],
    title: 'Campaign Blast',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/campaign-blast/create',
    exact: true,
    page: ['marketing', 'campaign-blast', 'create'],
    component: lazy(() => import('../../pages/marketing/create/create.campaign.blast')),
    layout: Main,
    role: ['Superuser'],
    title: 'Campaign Blast',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/campaign-blast/edit/:id',
    exact: true,
    page: ['marketing', 'campaign-blast', 'edit'],
    component: lazy(() => import('../../pages/marketing/edit/edit.campaign.blast')),
    layout: Main,
    role: ['Superuser'],
    title: 'Campaign Blast',
    context: ContextProviderMarketing,
  },
  {
    path: '/marketing/campaign-blast/detail/:id',
    exact: true,
    page: ['marketing', 'campaign-blast', 'detail'],
    component: lazy(() => import('../../pages/marketing/detail/detail.campaign.blast')),
    layout: Main,
    role: ['Superuser'],
    title: 'Campaign Blast',
    context: ContextProviderMarketing,
  },
];
