export interface IFormProfile {
  id: string;
  name: string;
  email: string;
  phone_number: number | null;
  old_password: string;
  new_password: string;
}

export const formProfile: IFormProfile = {
  id: '',
  name: '',
  email: '',
  phone_number: null,
  old_password: '',
  new_password: '',
};

export interface IFormChangePassword {
  password: string;
  new_password: string;
  repeat_new_password: string;
}
