import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderKategori from '../../pages/kategori/context/contextprovider.kategori';

export const kategoriRoutes = [
  {
    path: '/kategori/layanan',
    exact: true,
    page: ['kategori', 'layanan'],
    component: lazy(() => import('../../pages/kategori/layanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
  {
    path: '/kategori/layanan/create',
    exact: true,
    page: ['kategori', 'layanan', 'create'],
    component: lazy(() => import('../../pages/kategori/create/create.layanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
  {
    path: '/kategori/layanan/edit/:id',
    exact: true,
    page: ['kategori', 'layanan', 'edit'],
    component: lazy(() => import('../../pages/kategori/edit/edit.layanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
  {
    path: '/kategori/sub-layanan',
    exact: true,
    page: ['kategori', 'sub-layanan'],
    component: lazy(() => import('../../pages/kategori/sublayanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
  {
    path: '/kategori/sub-layanan/create',
    exact: true,
    page: ['kategori', 'sub-layanan', 'create'],
    component: lazy(() => import('../../pages/kategori/create/create.sublayanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
  {
    path: '/kategori/sub-layanan/edit/:id',
    exact: true,
    page: ['kategori', 'sub-layanan', 'edit'],
    component: lazy(() => import('../../pages/kategori/edit/edit.sublayanan')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori',
    context: ContextProviderKategori,
  },
];
