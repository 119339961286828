import moment from 'moment';

export interface IFormKategoriItem {
  name: string;
  merchant_id: null | string;
  description: string;
  is_active: number;
}

export const formKategoriItem: IFormKategoriItem = {
  name: '',
  merchant_id: null,
  description: '',
  is_active: 1,
};

interface IItemsItemVarianItem {
  name: string;
  price: number;
  status_promo: boolean;
  price_promo: boolean;
  date_start: string;
  date_end: string;
}

interface IItemsItemVarian {
  name: string;
  type: string;
  item?: Array<IItemsItemVarianItem>;
}

export interface IFormItemsItem {
  picture_url: any;
  name: string;
  description: string;
  product_base_price: number;
  merchant_id: string | null;
  merchant_product_category_id: string | null;
  stock_unit_id: string | null;
  is_allow_stock: boolean;
  is_discount: boolean;
  discount_price: number;
  show_discount_in_percentage: number;
  outlet: string;
  varian?: Array<IItemsItemVarian>;
  merchant_product_pictures?: Array<any> | null;
  discount_start_date: any;
  discount_end_date: any;
  is_draft: string;
  is_active: string;
  result_price: number;
}

export const formItemsItem: IFormItemsItem = {
  picture_url: null,
  name: '',
  description: '',
  product_base_price: 0,
  merchant_id: null,
  merchant_product_category_id: null,
  stock_unit_id: null,
  is_allow_stock: false,
  is_discount: false,
  discount_price: 0,
  show_discount_in_percentage: 0,
  outlet: '',
  varian: [],
  merchant_product_pictures: null,
  discount_start_date: null,
  discount_end_date: null,
  is_draft: '1',
  is_active: '1',
  result_price: 0,
};

export interface IParamsKategoriItem {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
  sort_by: string;
  order: string;
}

export interface IParamsItem {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
  merchant_outlet_id?: string;
  merchant_product_category_id?: string;
  sort_by: string;
  order: string;
}

export interface IVariantsItemVariants {
  variant_item_price: string;
  variant_item_name: string;
  discount_start_date: string;
  discount_end_date: string;
  variant_item_status: boolean;
  enable_variant_item_promo: boolean;
}

export interface IVariantsItems {
  id: string;
  variant_name: string;
  is_multiple_choice: number;
  is_required: number;
  merchant_product_variant_items: Array<IVariantsItemVariants>;
}

export const emptyVariantsItemVariants = {
  id: 0,
  variant_price: 0,
  variant_name: '',
  discount_start_date: '',
  discount_end_date: '',
  discount_price: 0,
  is_active: 0,
  is_discount: 0,
};

export const emptyVariantsItems = {
  id: '',
  variant_name: '',
  is_multiple_choice: 0,
  is_required: 0,
  merchant_product_variant_items: [
    {
      id: 0,
      variant_price: 0,
      variant_name: '',
      discount_start_date: moment(),
      discount_end_date: moment(),
      discount_price: 0,
      is_active: 0,
      is_discount: 0,
    },
  ],
};
