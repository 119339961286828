import {
  createKategoriItem,
  deleteKategoriItem,
  getAllListKategoriItem,
  getDetailKategoriItem,
  getlistKategoriItem,
  updateKategoriItem,
  updateStatusKategoriItem,
} from '../../data/items/kategori.item';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistKategoriItem = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistKategoriItem(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListKategoriItem = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListKategoriItem(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailKategoriItem = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailKategoriItem(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateKategoriItem = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createKategoriItem(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateKategoriItem = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateKategoriItem(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateStatusKategoriItem = (id: string, params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusKategoriItem(id, params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteKategoriItem = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteKategoriItem(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
