import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MARKETING } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistBanner = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/banner`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListBanner = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/banner/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailBanner = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/banner/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createBanner = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MARKETING}/banner`, data);
    // SuccessNotification({ description: 'Banner berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateBanner = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MARKETING}/banner/${id}`, data);
    // SuccessNotification({ description: 'Banner berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteBanner = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MARKETING}/banner/${id}`);
    SuccessNotification({ description: 'Banner berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const uploadImageBanner = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MARKETING}/banner/upload`, data);
    SuccessNotification({ description: 'Image Banner berhasil diupload' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
