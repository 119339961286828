import { Fragment, lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderSetting from '../../pages/setting/context/contextprovider.setting';

const noContext: React.FC<{ children: any }> = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export const settingRoutes = [
  {
    path: '/setting',
    exact: true,
    page: ['setting'],
    component: lazy(() => import('../../pages/setting')),
    layout: Main,
    role: ['Superuser'],
    title: 'Setting',
    context: ContextProviderSetting,
  },
  {
    path: '/notification',
    exact: true,
    page: ['notification'],
    component: lazy(() => import('../../pages/notification')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Notification',
    context: noContext,
  },
];
