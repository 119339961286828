import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { getAllListKategori } from '../../../data/kategori/kategori';
import { getAllListKategoriOutlet } from '../../../data/merchant/kategori.outlet';
import {
  UDeleteMerchant,
  UGetlistMerchant,
  UUpdateStatusMerchant,
} from '../../../usecase/merchant/merchant.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsMerchant } from '../model.merchant';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

interface IFilter {
  layanan: string;
  sub_layanan: string;
}

export const useModelMerchantList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsMerchant>({
    page: 1,
    limit: 10,
    q: '',
    outlet_group_id: '',
    layanan_id: '',
    outlet_category_id: '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const [listLayanan, setListLayanan] = useState<Array<any>>([]);
  const [listSubLayanan, setListSubLayanan] = useState<Array<any>>([]);
  const [listCategoryOutlet, setListCategoryOutlet] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    delete params.layanan_id;
    const temp: any = await dispatch(UGetlistMerchant(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  async function fetchLayanan(parent_id?: string) {
    if (parent_id) {
      const temp: any = await getAllListKategori({ type: 'sub-layanan', parent_id });
      setListSubLayanan(temp.data || []);
      fetchCategoryOutlet({ outlet_group_id: parent_id });
    } else {
      const temp: any = await getAllListKategori({ type: 'layanan' });
      setListLayanan(temp.data || []);
    }
  }

  async function fetchCategoryOutlet(params: any) {
    const temp: any = await getAllListKategoriOutlet(params);
    setListCategoryOutlet(temp.data);
  }

  useEffect(() => {
    fetchLayanan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const formParams = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusMerchant(data.id, formParams));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteMerchant(data.id));
    fetchList(params);
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleFilter = (data: IFilter) => {
    setParams({ ...params, ...data, page: 1 });
  };

  const handleChangeLayanan = (e: string) => fetchLayanan(e);

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleDelete,
    params,
    handleSearch,
    handleFilter,
    listLayanan,
    listSubLayanan,
    listCategoryOutlet,
    handleChangeLayanan,
    handleChangePage,
  };
};
