import React, { createContext } from 'react';
import { useModelMarketingForm } from './context.marketing.form';
import { useModelMarketingList } from './context.marketing.list';
import { useModelVoucherList } from './context.voucher.list';
import { useModelVoucherForm } from './context.voucher.form';
import { useModelVoucherTransactionList } from './context.voucher.transaction.list';
import { useModelBannerList } from './context.banner.list';
import { useModelBannerForm } from './context.banner.form';
import { useModelCampaignList } from './context.campaign.list';
import { useModelVoucherListInvoice } from './context.voucher.list.invoice';
import { useModelCampaignForm } from './context.campaign.form';

export const ContextMarketing = createContext<any>(null);

const ContextProviderMarketing: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelMarketingForm,
    useModelMarketingList,
    useModelVoucherList,
    useModelVoucherForm,
    useModelVoucherTransactionList,
    useModelBannerList,
    useModelBannerForm,
    useModelCampaignList,
    useModelVoucherListInvoice,
    useModelCampaignForm,
  };

  return <ContextMarketing.Provider value={context}>{props.children}</ContextMarketing.Provider>;
};

export default ContextProviderMarketing;
