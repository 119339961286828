import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalPrompt } from '../../../components/Modal/ModalPrompt';
import { UGetlistAllMerchant } from '../../../usecase/merchant/merchant.option.usecase';
import {
  UDeleteKaryawan,
  UGetlistKaryawan,
  UGetIsKaryawanLogin,
} from '../../../usecase/user-management/karyawan.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';
import { IParamsKaryawan } from '../model.usermanagement';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelKaryawanList = () => {
  const { data, status } = checkIsUserMerchant();
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsKaryawan>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    merchant_id: status ? data.id : '',
  });
  const dispatch = useDispatch();
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistKaryawan(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    async function fetchOption() {
      const temp: any = await dispatch(UGetlistAllMerchant({}));
      setMerchantList(temp.data);
    }

    fetchOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleDelete = async (data: any) => {
    const isLogin: any = await dispatch(UGetIsKaryawanLogin(data.id));
    try {
      if (isLogin) {
        return modalPrompt({
          text: 'Anda tidak dapat menghapus data, karena akun ini sedang login ke aplikasi.',
          danger: true,
          callback: () => {},
        });
      }

      await dispatch(UDeleteKaryawan(data.id));
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleFilter = (data: string) => {
    setParams({ ...params, merchant_id: data, page: 1 });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleDelete,
    params,
    handleSearch,
    handleChangePage,
    handleFilter,
    listMerchant,
    isUserMerchant: status,
  };
};
