import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getAllListItemVariants = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product-variants/all`, {
      params,
    });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createItemVariants = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/merchant-product-variants`, data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateItemVariants = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/merchant-product-variants/${id}`, data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteItemVariants = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/merchant-product-variants/${id}`);
    SuccessNotification({ description: 'Varian berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteItemVariantsItem = async (id: any) => {
  try {
    const res = await httpService.delete(
      `${SERVICE_MERCHANT}/merchant-product-variant-items/${id}`,
    );
    SuccessNotification({ description: 'Item Varian berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
