import { ErrorNotification } from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getAlllistQuantityUnit = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/qty-unit/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
