import moment from 'moment';
import { checkIsUserOutlet } from '../../merchant/utils/reference.review';

export const isDateNull = (value: string | null, format?: string) => {
  if (!value || value.includes('0001-01-01')) return null;

  return !format ? moment(value).utcOffset(420) : moment(value).utcOffset(420).format(format);
};

export const setDetailItemOutlet = (data: any) => {
  let outlet_product_variants = [];

  if (data.outlet_product_variants && data.outlet_product_variants.length > 0) {
    outlet_product_variants = data.outlet_product_variants.map((x: any) => {
      let outlet_product_variant_items = [];

      if (x.outlet_product_variant_items && x.outlet_product_variant_items.length > 0) {
        outlet_product_variant_items = x.outlet_product_variant_items.map((y: any) => ({
          ...y,
          variant_item_discount_price:
            (y.variant_item_price || 0) - (y.variant_item_discount_price || 0),
          discount_start_date: isDateNull(y.discount_start_date),
          discount_end_date: isDateNull(y.discount_end_date),
        }));
      }

      return {
        ...x,
        outlet_product_variant_items,
      };
    });
  }

  return {
    id: data.id,
    name: data.merchant_product.name,
    description: data.merchant_product.description,
    product_base_price: data.merchant_product.product_base_price,
    product_discount_price: data.merchant_product.discount_price || 0,
    promo_duration: `${
      data.merchant_product.discount_start_date
        ? moment(data.merchant_product.discount_start_date).utcOffset(420).format('DD-MM-YYYY')
        : '-'
    } s/d ${
      data.merchant_product.discount_end_date
        ? moment(data.merchant_product.discount_end_date).utcOffset(420).format('DD-MM-YYYY')
        : '-'
    }`,
    is_price_editable: data.is_price_editable,
    product_price: data.product_price,
    discount_price: data.discount_price,
    show_discount_in_percentage: data.show_discount_in_percentage,
    is_allow_stock: data.merchant_product.is_allow_stock === 1,
    discount_end_date: isDateNull(data.discount_end_date),
    discount_start_date: isDateNull(data.discount_start_date),
    outlet_product_variants,
    is_discount: data.is_discount === 1,
    outlet_price: totalDiscountPriceInPercent(
      data.show_discount_in_percentage === 1,
      data.product_price,
      data.discount_price,
    ),
    stock: data.stock,
  };
};

export const createPayloadUpdateOutletItem = (dataForm: any, values: any) => {
  let outlet_product_variants = [];

  const checkIsRequiredAndIsDiscount = (data: any) => {
    if (!data) return 0;
    if (typeof data === 'boolean') return data ? 1 : 0;

    return data;
  };

  outlet_product_variants =
    values.outlet_product_variants &&
    values.outlet_product_variants.map((x: any) => {
      let outlet_product_variant_items = [];

      if (x.outlet_product_variant_items && x.outlet_product_variant_items.length > 0) {
        outlet_product_variant_items = x.outlet_product_variant_items.map((y: any) => ({
          id: y.id,
          merchant_variant_item_id: y.merchant_variant_item_id,
          is_active: y.is_active ? 1 : 0,
          variant_name: y.variant_name,
          variant_item_price: y.variant_item_price,
          is_discount: checkIsRequiredAndIsDiscount(y.is_discount),
          variant_item_discount_price: checkIsRequiredAndIsDiscount(y.is_discount)
            ? (y.variant_item_price || 0) - (y.variant_item_discount_price || 0)
            : 0,
          discount_start_date: y.discount_start_date
            ? moment(y.discount_start_date).utcOffset(420).format('YYYY-MM-DDT00:00:00Z')
            : null,
          discount_end_date: y.discount_end_date
            ? moment(y.discount_end_date).utcOffset(420).format('YYYY-MM-DDT23:59:59Z')
            : null,
          outlet_product_variant_id: y.outlet_product_variant_id,
        }));
      }

      return {
        variant_name: x.variant_name,
        is_multiple_choice: x.is_multiple_choice,
        is_required: checkIsRequiredAndIsDiscount(x.is_required),
        outlet_product_variant_items,
        id: x.id,
        merchant_variant_id: x.merchant_variant_id,
        outlet_product_id: x.outlet_product_id,
      };
    });

  return {
    merchant_outlet_id: dataForm.merchant_outlet_id,
    merchant_product_id: dataForm.merchant_product_id,
    stock_unit_id: dataForm.stock_unit_id,
    stock: values.stock,
    is_available: dataForm.is_available,
    product_price: values.product_price,
    is_discount: values.is_discount ? 1 : 0,
    show_discount_in_percentage: values.show_discount_in_percentage,
    discount_price: values.discount_price,
    discount_start_date: values.discount_start_date
      ? moment(values.discount_start_date).utcOffset(420).format('YYYY-MM-DDT00:00:00Z')
      : null,
    discount_end_date: values.discount_end_date
      ? moment(values.discount_end_date).utcOffset(420).format('YYYY-MM-DDT23:59:59Z')
      : null,
    outlet_product_variants,
  };
};

export const createPayloadOutletItem = (values: any, merchantOutletId?: string) => {
  const { data } = checkIsUserOutlet();
  let outlet_product_variants = [];

  outlet_product_variants =
    values.merchant_product_variants &&
    values.merchant_product_variants.map((x: any) => {
      let outlet_product_variant_items = [];

      if (x.merchant_product_variant_items && x.merchant_product_variant_items.length > 0) {
        outlet_product_variant_items = x.merchant_product_variant_items.map((y: any) => ({
          merchant_variant_item_id: y.id || '',
          is_active: y.is_active ? 1 : 0,
          variant_name: y.variant_name,
          variant_item_price: y.variant_price,
          is_discount: y.is_discount,
          variant_item_discount_price: y.discount_price,
          discount_start_date: isDateNull(y.discount_start_date, 'YYYY-MM-DDT00:00:00Z'),
          discount_end_date: isDateNull(y.discount_end_date, 'YYYY-MM-DDT23:59:59Z'),
        }));
      }

      return {
        merchant_variant_id: x.id || '',
        variant_name: x.variant_name,
        is_multiple_choice: x.is_multiple_choice || 0,
        is_required: x.is_required,
        outlet_product_variant_items,
      };
    });

  return {
    merchant_outlet_id: data.id || merchantOutletId,
    merchant_product_id: values.id,
    stock_unit_id: values.stock_unit_id,
    stock: values.is_allow_stock ? 0 : values.stock || 0,
    is_available: values.is_allow_stock ? 0 : 1,
    product_price: values.product_base_price,
    is_discount: values.is_discount,
    show_discount_in_percentage: values.show_discount_in_percentage,
    discount_price: values.discount_price,
    discount_start_date: isDateNull(values.discount_start_date, 'YYYY-MM-DDT00:00:00Z'),
    discount_end_date: isDateNull(values.discount_end_date, 'YYYY-MM-DDT23:59:59Z'),
    outlet_product_variants,
  };
};

export const totalDiscountPriceInPercent = (
  isPercent: boolean,
  price: number,
  discount: number,
) => {
  if (isPercent) return Math.round((100 * (price - discount)) / price);

  return price - discount;
};
