import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { openNotificationWithIcon } from '../components/Header/section/CustomNotification';
import { FIREBASE_CONFIG } from '../config/keys';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIREBASE_CONFIG.API_KEY,
  authDomain: FIREBASE_CONFIG.AUTH_DOMAIN,
  projectId: FIREBASE_CONFIG.PROJECT_ID,
  storageBucket: FIREBASE_CONFIG.STORAGE_BUCKET,
  messagingSenderId: FIREBASE_CONFIG.MESSAGE_SENDER_ID,
  appId: FIREBASE_CONFIG.APP_ID,
  measurementId: FIREBASE_CONFIG.MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: FIREBASE_CONFIG.VAPID_KEY,
    });
    if (currentToken) {
      return currentToken;
      // onSubscribeTopic(currentToken, role);
    } else {
      // Show permission request UI
      return Promise.reject('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    return Promise.reject('An error occurred while retrieving token. ' + err);
  }
};

export const onMessageListener = (callback = () => {}) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      const {
        notification: { body, title },
      } = payload;

      openNotificationWithIcon({ title, description: body, type: 'info' });
      callback();
      resolve(payload);
    });
  });

export const onSubscribeTopic = (token: string, role: string) => {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/user-${role}`, {
    method: 'POST',
    headers: new Headers({
      Authorization:
        'key=AAAAKxEVx1Q:APA91bHNWXQ8wWH0ZZ0rOT8ZNSG5ha_X97Zhvl1NcIELTJd6htlVr8JdY4IOV6MvAm8-uepcGe-5nwFQJa1xea7wtRPF7VbLvPCw9mNxVUWUxjytNRTxLFu75MB2ajLQBLLw1MUDzgo6',
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
      }
      console.log('Subscribed to success');
    })
    .catch((error) => {
      console.error(error);
    });
};

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('firebase-messaging-sw.js', {
        scope: '/',
      });
      if (registration.installing) {
        console.log('Service worker installing');
      } else if (registration.waiting) {
        console.log('Service worker installed');
      } else if (registration.active) {
        console.log('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};
