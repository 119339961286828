import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UCreateRole,
  UGetDetailRole,
  UUpdateRole,
} from '../../../usecase/user-management/role.usecase';
import { formRole, IFormRole } from '../model.usermanagement';

export const useModelRoleForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormRole>(formRole);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailRole(id));
      setForm(temp.data);
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormRole) => {
    console.log(values);

    const formData = {
      name: values.name,
      is_active: true,
    };

    try {
      if (id) {
        await dispatch(UUpdateRole(id, formData));
      } else {
        await dispatch(UCreateRole(formData));
      }
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
  };
};
