import { ReactComponent as IconDashboard } from '../assets/icons/icon_dashboard.svg';
import { ReactComponent as IconKategori } from '../assets/icons/icon_kategori.svg';
import { ReactComponent as IconItem } from '../assets/icons/icon_item.svg';
import { ReactComponent as IconMerchant } from '../assets/icons/icon_merchant.svg';
import { ReactComponent as IconTransaksi } from '../assets/icons/icon_transaksi.svg';
import { ReactComponent as IconMarketing } from '../assets/icons/icon_marketing.svg';
import { ReactComponent as IconUsermanager } from '../assets/icons/icon_usermanager.svg';
import { ReactComponent as IconSetting } from '../assets/icons/setting.svg';
import { ReactComponent as IconChat } from '../assets/icons/chat.svg';

export const sidebarMenus = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <IconDashboard width={20} />,
    role: ['Superuser', 'Outlet', 'Owner'],
    subrute: [],
  },
  {
    name: 'Kategori',
    path: '/kategori',
    icon: <IconKategori width={20} />,
    role: ['Superuser'],
    subrute: [
      {
        name: 'Layanan',
        path: '/kategori/layanan',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Sub-Layanan',
        path: '/kategori/sub-layanan',
        icon: null,
        role: ['Superuser'],
      },
    ],
  },
  {
    name: 'Items',
    path: '/items',
    icon: <IconItem width={20} />,
    role: ['Superuser', 'Owner'],
    subrute: [
      {
        name: 'Kategori Item',
        path: '/items/kategori-item',
        icon: null,
        role: ['Superuser', 'Owner'],
      },
      {
        name: 'Item',
        path: '/items/item',
        icon: null,
        role: ['Superuser', 'Owner'],
      },
    ],
  },
  {
    name: 'Items',
    path: '/items-outlet',
    icon: <IconItem width={20} />,
    role: ['Outlet'],
    subrute: [
      {
        name: 'Item',
        path: '/items-outlet/item',
        icon: null,
        role: ['Outlet'],
      },
    ],
  },
  {
    name: 'Merchant',
    path: '/merchant',
    icon: <IconMerchant width={20} />,
    role: ['Superuser', 'Owner', 'Outlet'],
    subrute: [
      {
        name: 'Kategori Outlet',
        path: '/merchant/kategori-outlet',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Merchant',
        path: '/merchant/merchant',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Outlet',
        path: '/merchant/outlet',
        icon: null,
        role: ['Superuser', 'Owner'],
      },
      {
        name: 'Review',
        path: '/merchant/review',
        icon: null,
        role: ['Superuser', 'Owner', 'Outlet'],
      },
    ],
  },
  {
    name: 'Transaksi',
    path: '/transaksi',
    icon: <IconTransaksi width={20} />,
    role: ['Superuser', 'Outlet', 'Owner'],
    subrute: [
      {
        name: 'Transaksi Masuk',
        path: '/transaksi/transaksi-masuk',
        icon: null,
        role: ['Superuser', 'Outlet', 'Owner'],
      },
      // {
      //   name: 'Transaksi Keluar',
      //   path: '/transaksi/transaksi-keluar',
      //   icon: null,
      //   role: ['Superuser', 'Outlet', 'Owner'],
      // },
    ],
  },
  {
    name: 'Marketing',
    path: '/marketing',
    icon: <IconMarketing width={20} />,
    role: ['Superuser'],
    subrute: [
      {
        name: 'Voucher',
        path: '/marketing/voucher',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Banner',
        path: '/marketing/banner',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Campaign Blast',
        path: '/marketing/campaign-blast',
        icon: null,
        role: ['Superuser'],
      },
    ],
  },
  {
    name: 'User Management',
    path: '/user-management',
    icon: <IconUsermanager width={20} />,
    role: ['Superuser', 'Owner'],
    subrute: [
      {
        name: 'Daftar User',
        path: '/user-management/user',
        icon: null,
        role: ['Superuser'],
      },
      // {
      //   name: 'Daftar Role',
      //   path: '/user-management/role',
      //   icon: null,
      // },
      {
        name: 'Daftar Customer',
        path: '/user-management/customer',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Daftar Integrasi Customer',
        path: '/user-management/integrated-customer',
        icon: null,
        role: ['Superuser'],
      },
      {
        name: 'Daftar Karyawan',
        path: '/user-management/karyawan',
        icon: null,
        role: ['Superuser', 'Owner'],
      },
    ],
  },
  {
    name: 'Setting',
    path: '/setting',
    icon: <IconSetting width={20} />,
    role: ['Superuser'],
    subrute: [],
  },
  {
    name: 'Chat',
    path: '/chat',
    icon: <IconChat width={20} />,
    role: ['Superuser', 'Outlet', 'Owner'],
    subrute: [],
  },
];
