export interface IFormKategoriItem {
  name: string;
  merchant_id: string;
  description: string;
}

export const formKategoriItem: IFormKategoriItem = {
  name: '',
  merchant_id: '',
  description: '',
};

interface IItemsItemVarianItem {
  name: string;
  price: number;
  status_promo: boolean;
  price_promo: boolean;
  date_start: string;
  date_end: string;
}

interface IItemsItemVarian {
  name: string;
  type: string;
  item?: Array<IItemsItemVarianItem>;
}

export interface IFormItemsItem {
  image: any;
  name: string;
  description: string;
  product_base_price: number | null;
  merchant_id: string | null;
  merchant_product_category_id: string | null;
  qty_unit_id: string | null;
  stock_aware: boolean;
  status_promo: boolean;
  price_promo: number;
  satuan: string;
  outlet: string;
  date_start: string;
  date_end: string;
  varian?: Array<IItemsItemVarian>;
  merchant_product_pictures: Array<any>;
}

export const formItemsItem: IFormItemsItem = {
  image: null,
  name: '',
  description: '',
  product_base_price: null,
  merchant_id: null,
  merchant_product_category_id: null,
  qty_unit_id: null,
  stock_aware: false,
  status_promo: true,
  price_promo: 0,
  satuan: '',
  outlet: '',
  date_start: '',
  date_end: '',
  varian: [],
  merchant_product_pictures: [],
};

export interface IParamsKategoriItem {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
}

export interface IParamsItem {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
  merchant_outlet_id?: string;
  merchant_product_id?: string;
  is_active?: string;
  is_draft?: string;
  order: string;
  sort_by: string;
}

export interface IDataTypePopupItem {
  name: string;
  price: number;
  category_item: string;
}
