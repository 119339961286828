import gatewayHttpService from '../../services/gateway.http.service';
import { SERVICE_GATEWAY } from '../../config/keys';

import {
  IParamsIntegratedCustomer,
  IParamsProperty,
} from '../../pages/user-manager/model.usermanagement';
import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';

export const getListIntegratedCustomer = async (params: IParamsIntegratedCustomer) => {
  try {
    const res = await gatewayHttpService.get(`${SERVICE_GATEWAY}/users`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailIntegratedCustomer = async (email: string, phone: string) => {
  try {
    const res = await gatewayHttpService.get(
      `${SERVICE_GATEWAY}/users/phone/${phone}/email/${email}`,
    );

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const exportIntegratedCustomer = async () => {
  try {
    const res = await gatewayHttpService.get(`${SERVICE_GATEWAY}/users/export`);
    SuccessNotification({ description: 'Export data customer berhasil' });
    return res.data;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getListIntegratedCustomerProperty = async (
  email: string,
  phone: string,
  params: IParamsProperty,
) => {
  try {
    const res = await gatewayHttpService.get(
      `${SERVICE_GATEWAY}/users/phone/${phone}/email/${email}/property`,
      {
        params,
      },
    );

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getListProperty = async (params: IParamsProperty) => {
  try {
    const res = await gatewayHttpService.get(`${SERVICE_GATEWAY}/users/filter`, {
      params,
    });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
