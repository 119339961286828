import { dataRawUser, getUser } from '../../../utils/localStorage';

export const listRating = ['1', '2', '3', '4', '5'] as const;

export const checkIsUserOutlet = () => {
  const user = getUser();
  const currentRole: Array<string> = user?.roles || [];
  const isUserOutlet = currentRole.includes('Outlet');

  return isUserOutlet
    ? {
        status: true,
        data: dataRawUser()?.outlet || null,
      }
    : {
        status: false,
        data: '',
      };
};
