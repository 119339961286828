import { layoutTypes } from './item.outlet.types';

const initState = {
  isDelete: true,
};

export const itemOutletReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case layoutTypes.DELETE_MY_ITEM:
      return {
        ...state,
        isDelete: action.payload,
      };

    default:
      return state;
  }
};
