import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalPrompt } from '../../../components/Modal/ModalPrompt';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UCreateUser,
  UGetDetailUser,
  UGetIsUserLogin,
  UUpdateUser,
} from '../../../usecase/user-management/user.usecase';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';
import { formUser, IFormUser } from '../model.usermanagement';

export const useModelUserForm = () => {
  const { status } = checkIsUserMerchant();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormUser>(formUser);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailUser(id));
      setForm(temp.data);
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormUser) => {
    const formData: any = {
      name: values.name,
      email: values.email,
      phone_number: values.phone_number,
      password: values.password,
      is_active_account: form.is_active_account,
    };

    try {
      if (id) {
        const isLogin: any = await dispatch(UGetIsUserLogin(id));
        if (isLogin) {
          return modalPrompt({
            text: 'Anda tidak dapat melakukan perubahan data, karena akun ini sedang login ke aplikasi.',
            danger: true,
            callback: () => {},
            width: 420,
          });
        }
        formData.roles = form.roles;

        await dispatch(UUpdateUser(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        formData.roles = !status ? ['Superuser'] : ['Outlet'];

        await dispatch(UCreateUser(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Layanan',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
  };
};
