import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UDeleteKategori,
  UGetlistKategori,
  UUpdateStatusKategori,
} from '../../../usecase/kategori/kategori.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsSubLayanan } from '../model.kategori';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelLayananList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsSubLayanan>({
    page: 1,
    limit: 10,
    q: '',
    parent_id: '',
    type: 'layanan',
    sort_by: 'created_at',
    order: 'desc',
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistKategori(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const formParams = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusKategori(data.id, formParams));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteKategori(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleDelete,
    params,
    handleSearch,
    handleChangePage,
  };
};
