import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logo from '../../../assets/icons/MO_Logo_big.svg';
import profileIcon from '../../../assets/icons/profile-icon.svg';

import { getUser } from '../../../utils/localStorage';
import { checkDetailProfile, getFlagRole } from '../../Header/section/utils';

interface Props {
  isMobile?: boolean;
  isUpdateUser?: boolean;
}

const BoxProfileMobile: React.FC<Props> = (props) => {
  const { isMobile, isUpdateUser } = props;
  const [current, setCurrent] = useState<{
    box: boolean;
    name: string;
    status: string;
  }>({
    box: false,
    name: '',
    status: '',
  });

  const user = getUser();

  useEffect(() => {
    async function checkUser() {
      const res = await checkDetailProfile(user);

      setCurrent({ box: res.box, name: res.name, status: res.status });
    }

    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUser]);

  const styleText = isMobile ? { color: 'white' } : {};

  return (
    <div className="box-user">
      <img className="mt-4" width={144} src={logo} alt="logo" />
      <br />
      <img className="mt-2" width={60} src={profileIcon} alt="profile-icon" />
      <br />
      <h5 className="mb-0" style={styleText}>
        {current.name}
      </h5>
      <h6 className="text-flag-profile" style={styleText}>
        {current.box && (
          <Fragment>
            <span>
              <img width={10} src={getFlagRole(user)} alt="flag" />
            </span>
            {current.status}
          </Fragment>
        )}
      </h6>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isUpdateUser: state.layout.isUpdateUser,
});

export default connect(mapStateToProps, null)(BoxProfileMobile);
