import { Drawer, Layout, Menu } from 'antd';
import { DesktopOutlined } from '@ant-design/icons';
import { Fragment } from 'react';
import { sidebarMenus } from '../../config/menus';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { getUser } from '../../utils/localStorage';
import { checkMenuRoles } from './utils';
import BoxProfileMobile from './section/Box.Profile.Mobile';

interface Props {
  collapsed: boolean;
  isMobile: boolean;
  setCollapsed: (flag: boolean) => void;
}

const { Sider } = Layout;
const { SubMenu } = Menu;

const defaultIcon = <DesktopOutlined />;
const Index: React.FC<Props> = (props) => {
  const { collapsed, isMobile, setCollapsed } = props;
  const navigate = useNavigate();
  const userRoles = checkMenuRoles(getUser()?.roles);

  return (
    <Fragment>
      {!isMobile && (
        <Sider width={220} className="d-none d-sm-block box-sider">
          <Menu defaultSelectedKeys={['1']} mode="inline" style={{ background: 'transparent' }}>
            {sidebarMenus.map(
              (val) =>
                val.role.includes(userRoles) &&
                (val.subrute.length === 0 ? (
                  <Menu.Item
                    key={val.path}
                    icon={val.icon || defaultIcon}
                    onClick={() => navigate(val.path)}>
                    {val.name}
                  </Menu.Item>
                ) : (
                  <SubMenu key={val.path} icon={val.icon || defaultIcon} title={val.name}>
                    {val.subrute.map(
                      (detail) =>
                        detail.role.includes(userRoles) && (
                          <Menu.Item key={detail.path} onClick={() => navigate(detail.path)}>
                            {detail.name}
                          </Menu.Item>
                        ),
                    )}
                  </SubMenu>
                )),
            )}
          </Menu>
        </Sider>
      )}

      {isMobile && (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setCollapsed(false)}
          visible={collapsed}
          key="left"
          bodyStyle={{ background: '#001529', paddingLeft: 0, paddingRight: 0 }}
          width={300}>
          <div className="d-flex justify-content-center text-center">
            <BoxProfileMobile isMobile={isMobile} />
          </div>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            {sidebarMenus.map(
              (val) =>
                val.role.includes(userRoles) &&
                (val.subrute.length === 0 ? (
                  <Menu.Item
                    key={val.path}
                    icon={val.icon || defaultIcon}
                    onClick={() => navigate(val.path)}>
                    {val.name}
                  </Menu.Item>
                ) : (
                  <SubMenu key={val.path} icon={val.icon || defaultIcon} title={val.name}>
                    {val.subrute.map(
                      (detail) =>
                        detail.role.includes(userRoles) && (
                          <Menu.Item key={detail.path} onClick={() => navigate(detail.path)}>
                            {detail.name}
                          </Menu.Item>
                        ),
                    )}
                  </SubMenu>
                )),
            )}
          </Menu>
        </Drawer>
      )}
    </Fragment>
  );
};

export default Index;
