import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistKategoriItem = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product-category`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListKategoriItem = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product-category/all`, {
      params,
    });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailKategoriItem = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product-category/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createKategoriItem = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/merchant-product-category`, data);
    // SuccessNotification({ description: 'Kategori item berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateKategoriItem = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/merchant-product-category/${id}`, data);
    // SuccessNotification({ description: 'Kategori item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusKategoriItem = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(
      `${SERVICE_MERCHANT}/merchant-product-category/${id}`,
      undefined,
      { params },
    );
    // SuccessNotification({ description: 'Kategori item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteKategoriItem = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/merchant-product-category/${id}`);
    SuccessNotification({ description: 'Kategori item berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
