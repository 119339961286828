import { requestLogin, requestLogout, requestRefreshToken } from '../data/auth';
import {
  setLoginFailure,
  setLoginRequest,
  setLoginSuccess,
  setLogoutFailure,
  setLogoutRequest,
  setLogoutSuccess,
  setRefreshTokenFailure,
  setRefreshTokenRequest,
  setRefreshTokenSuccess,
} from '../redux/auth/auth.actions';

export const URequestLogin = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoginRequest());
    const res: any = await requestLogin(data);
    dispatch(setLoginSuccess(res.data));
    return res?.data;
  } catch (error: any) {
    dispatch(setLoginFailure());
    return Promise.reject(error.response);
  }
};

export const URequestLogout = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLogoutRequest());
    const res: any = await requestLogout(params);
    dispatch(setLogoutSuccess());
    return res;
  } catch (error) {
    dispatch(setLogoutFailure());
    return Promise.reject(error);
  }
};

export const URefreshToken = (refreshToken: string) => async (dispatch: any) => {
  try {
    dispatch(setRefreshTokenRequest());
    const res: any = await requestRefreshToken(refreshToken);
    dispatch(setRefreshTokenSuccess(res.data));
    return res?.data;
  } catch (error: any) {
    dispatch(setRefreshTokenFailure());
    return Promise.reject(error.response);
  }
};
