/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UDeleteBanner,
  UGetlistBanner,
  UUpdateBanner,
} from '../../../usecase/marketing/banner.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsBanner } from '../model.marketing';

export const useModelBannerList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsBanner>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    valid_until: '',
    valid_from: '',
    is_active: '',
  });
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistBanner(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, is_active: boolean) => {
    try {
      const payload = {
        ...data,
        is_active,
      };

      await dispatch(UUpdateBanner(data.id, payload));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteBanner(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleFilter = (data: any) => {
    let filter = {
      valid_from:
        data.periode && data.periode[0] ? moment(data.periode[0]).format('YYYY-MM-DD') : '',
      valid_until:
        data.periode && data.periode[1] ? moment(data.periode[1]).format('YYYY-MM-DD') : '',
      is_active: data.is_active,
    } as IParamsBanner;

    setParams({ ...params, ...filter, page: 1 });
  };

  const handleChangePage = (page: number) => setParams({ ...params, page });

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleDelete,
    handleSearch,
    handleFilter,
    handleChangePage,
    handleSetStatus,
  };
};
