import {
  ErrorNotification,
  WarningErrorNotification,
} from '../../components/Notification/CustomNotification';
import {
  createItem,
  deleteItem,
  getAllListItem,
  getDetailItem,
  getlistItem,
  updateItem,
  updateStatusDraftItem,
  updateStatusItem,
} from '../../data/items/item';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistItem = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistItem(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListItem = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListItem(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailItem = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailItem(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateItem = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createItem(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateItem = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateItem(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateStatusItem = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusItem(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteItem = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteItem(id);
    return res;
  } catch (error: any) {
    const { data }: { data: { message?: string } } = error.response.data;

    if (data.message && data.message.includes('Product sedang digunakan di outlet')) {
      return WarningErrorNotification(error, {
        message: 'PERHATIAN',
        description:
          'Produk sedang digunakan di outlet, Silahkan hapus outlet produk terlebih dahulu',
      });
    }
    return ErrorNotification(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UUpdateStatusDraftItem = (id: string, params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusDraftItem(id, params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
