interface IAuth {
  user?: any;
  access_token?: string;
  token_id?: string;
  refresh_token_id?: string;
  refresh_token?: string;
  merchant?: any;
  outlet?: any;
  fcm_token?: any;
}

export const dataRawUser = () => {
  const dataRaw: string | null = localStorage.getItem('moaja:user') || '';
  if (!dataRaw) return null;
  if (dataRaw === 'undefined') return null;
  if (dataRaw === undefined) return null;
  const parsedData: IAuth = JSON.parse(dataRaw);

  return parsedData;
};

export const getToken = () => dataRawUser()?.access_token;
export const getUser = () => dataRawUser()?.user;
export const getRefreshToken = () => dataRawUser()?.refresh_token;
export const getFcmToken = () => dataRawUser()?.fcm_token;

export const setUser = async (data: any) =>
  localStorage.setItem('moaja:user', JSON.stringify(data));

export const setUpdateUser = async (user: any, roleData?: any) => {
  const dataRaw: any = localStorage.getItem('moaja:user');
  const newData = { ...JSON.parse(dataRaw), user, ...roleData };

  setUser(newData);
};

export const setUpdateFcmToken = async (fcmToken: string) => {
  const dataRaw: any = localStorage.getItem('moaja:user');
  const newData = { ...JSON.parse(dataRaw), fcm_token: fcmToken };

  setUser(newData);
};

export const deleteLocalUser = () => localStorage.removeItem('moaja:user');
