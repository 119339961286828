import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formIntegratedCustomer, IFormIntegratedCustomer } from '../model.usermanagement';
import { UGetDetailIntegratedCustomer } from '../../../usecase/user-management/integrated.customer.usecase';

export const useModelIntegratedCustomerForm = () => {
  const { email, phone } = useParams();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormIntegratedCustomer>(formIntegratedCustomer);

  useEffect(() => {
    async function fetchDetail(email: string, phone: string) {
      const temp: any = await dispatch(UGetDetailIntegratedCustomer(email, phone));
      setForm(temp.data);
    }

    if (email && phone) {
      fetchDetail(email, phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone]);

  return {
    form,
  };
};
