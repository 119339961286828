import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UGetlistVoucherTransaction } from '../../../usecase/marketing/voucher.transaction.usecase';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsVoucherTransaction } from '../model.marketing';

export const useModelVoucherTransactionList = () => {
  const { id = '' } = useParams();
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsVoucherTransaction>({
    page: 1,
    limit: 10,
    status: '',
  });
  const dispatch = useDispatch();

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistVoucherTransaction(params, id));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleChangePage,
  };
};
