import React, { createContext } from 'react';
import { useModelFormAuth } from './context.auth.form';

export const ContextAuth = createContext<any>(null);

const ContextProviderLogin: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelFormAuth,
  };

  return <ContextAuth.Provider value={context}>{props.children}</ContextAuth.Provider>;
};

export default ContextProviderLogin;
