import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UCreateKategori,
  UGetDetailKategori,
  UUpdateKategori,
} from '../../../usecase/kategori/kategori.usecase';
import { formLayanan, IFormLayanan } from '../model.kategori';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';

export const useModelLayananForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormLayanan>(formLayanan);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailKategori(id));
      setForm({
        ...temp.data,
        ...(temp.data.picture_url && {
          picture_url: temp.data.picture_url,
        }),
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormLayanan) => {
    console.log(values);

    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('code', values.code);
    formData.append('payment_product_code', values.payment_product_code);
    formData.append(
      'file',
      values.picture_url ? values.picture_url.originFileObj || values.picture_url : '',
    );
    formData.append('display_index', values.display_index || '');

    try {
      if (id) {
        formData.append('is_active', String(form.is_active));
        await dispatch(UUpdateKategori(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        formData.append('is_active', '1');
        await dispatch(UCreateKategori(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Layanan',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
  };
};
