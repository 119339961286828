import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UDeleteUser,
  UExportCustomer,
  UGetlistUser,
  UUpdateStatusUser,
} from '../../../usecase/user-management/user.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsUser } from '../model.usermanagement';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelCustomerList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsUser>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    roles: 'User',
    start_date: '',
    end_date: '',
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistUser(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteUser(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleFilter = (data: any) => {
    setParams({ ...params, page: 1, ...data });
  };

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const payload = { is_active_account: status };
      await dispatch(UUpdateStatusUser(data.id, payload));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    try {
      const res: any = await dispatch(UExportCustomer());

      window.location = res.link;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleDelete,
    params,
    handleSearch,
    handleChangePage,
    handleFilter,
    handleSetStatus,
    handleExport,
  };
};
