export interface IFormLayanan {
  name: string;
  code: string;
  payment_product_code: string;
  picture_url: any;
  display_index: string | null;
  is_active?: number;
}

export const formLayanan: IFormLayanan = {
  name: '',
  code: '',
  payment_product_code: '',
  picture_url: null,
  display_index: null,
};

export interface IFormSubLayanan {
  name: string;
  code: string;
  parent_id: string | null;
  picture_url: any;
  display_index: string | null;
  is_active?: number;
}

export const formSubLayanan: IFormSubLayanan = {
  name: '',
  code: '',
  parent_id: null,
  picture_url: null,
  display_index: null,
};

export interface IParamsSubLayanan {
  page: number;
  limit: number;
  q?: string;
  parent_id?: string;
  type?: string;
  is_active?: string;
  sort_by: string;
  order: string;
}
