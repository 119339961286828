import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { UGetAllListKategori } from '../../../usecase/kategori/kategori.usecase';
import {
  UCreateKategoriOutlet,
  UGetDetailKategoriOutlet,
  UUpdateKategoriOutlet,
} from '../../../usecase/merchant/kategori.outlet.usecase';
import { formKategoriOutlet, IFormKategoriOutlet } from '../model.merchant';
// import { formLayanan, IFormLayanan } from "../model.kategori";

export const useModelKategoriOutletForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormKategoriOutlet>(formKategoriOutlet);
  const [listLayanan, setLayananList] = useState<Array<any>>([]);
  const [listSubLayanan, setSubLayananList] = useState<Array<any>>([]);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailKategoriOutlet(id));
      setForm({
        ...temp.data,
        outlet_group_id: temp.data.layanan ? temp.data.layanan.id : null,
        sub_outlet_group_id: temp.data.sub_layanan ? temp.data.sub_layanan.id : null,
      });
      if (temp.data.layanan) {
        fetchLayanan(temp.data.layanan.id);
      }
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function fetchLayanan(parent_id?: string) {
    if (parent_id) {
      const temp: any = await dispatch(UGetAllListKategori({ type: 'sub-layanan', parent_id }));
      setSubLayananList(temp.data);
    } else {
      const temp: any = await dispatch(UGetAllListKategori({ type: 'layanan' }));
      setLayananList(temp.data);
    }
  }

  const handleChangeLayanan = (e: string) => {
    if (e) {
      fetchLayanan(e);
    }
  };

  useEffect(() => {
    fetchLayanan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: IFormKategoriOutlet) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append(
      'file',
      values.picture_url ? values.picture_url.originFileObj || values.picture_url : '',
    );
    formData.append(
      'outlet_group_id',
      String(values.sub_outlet_group_id || values.outlet_group_id || ''),
    );

    try {
      if (id) {
        formData.append('is_active', String(form.is_active));
        await dispatch(UUpdateKategoriOutlet(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        formData.append('is_active', '1');
        await dispatch(UCreateKategoriOutlet(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Kategori Outlet',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
    handleChangeLayanan,
    listLayanan,
    listSubLayanan,
  };
};
