import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { UGetListProperty } from '../../../usecase/user-management/integrated.customer.usecase';
import useDebounce from '../../../utils/debounce';
import { IParamsUser } from '../model.usermanagement';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelPropertyList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsUser>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetListProperty(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleSearch,
  };
};
