export interface IFormRole {
  name: string;
  module_access: Array<any>;
}

export const formRole: IFormRole = {
  name: '',
  module_access: [],
};

export interface IParamsRole {
  page: number;
  limit: number;
  q?: string;
  orderBy?: string;
}

export interface IParamsUser {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  roles?: string;
  start_date?: string;
  end_date?: string;
}

export interface IParamsKaryawan {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  merchant_id?: string;
}

export interface IParamsIntegratedCustomer {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  start_date?: string;
  end_date?: string;
  property_codes?: Array<string>;
}

export interface IParamsProperty {
  page: number;
  limit: number;
  sort_by: string;
  order: string;
  q?: string;
}

export interface IFormUser {
  id: string;
  name: string;
  phone_number: string;
  email: string;
  roles: Array<any>;
  password: string;
  is_active_account: boolean;
  is_account_logged?: boolean;
  is_verified?: boolean;
  email_is_verified?: boolean;
}

export const formUser: IFormUser = {
  id: '',
  name: '',
  phone_number: '',
  email: '',
  roles: [],
  password: '',
  is_active_account: true,
};

export interface IFormKaryawan {
  id?: string;
  name: string;
  phone_number: string;
  email: string;
  address: string;
  sex: string;
  birth_place: string;
  date_of_birth: string | null;
  join_date: string | null;
  employment: string;
  merchant_id: string | null;
  merchant_outlet_id?: string | null;
  password?: string;
  is_active_account: boolean;
  role: string | null;
}

export const formKaryawan: IFormKaryawan = {
  id: '',
  name: '',
  phone_number: '',
  email: '',
  address: '',
  sex: '1',
  birth_place: '',
  date_of_birth: null,
  join_date: null,
  employment: '',
  merchant_id: null,
  merchant_outlet_id: null,
  password: '',
  is_active_account: false,
  role: null,
};

export interface IFormIntegratedCustomer {
  user_id: string;
  name: string;
  phone: string;
  email: string;
  updated_at: string;
  created_at: string;
  property_codes: Array<string>;
}

export const formIntegratedCustomer: IFormIntegratedCustomer = {
  user_id: '',
  name: '',
  phone: '',
  email: '',
  updated_at: '',
  created_at: '',
  property_codes: [],
};

export interface IFormProperty {
  id: string;
  user_id: string;
  property_name: string;
  code: string;
}
