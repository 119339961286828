import { initalWorkingDays, workingDays } from './config/ref';

export interface IFormKategoriOutlet {
  picture_url: any;
  name: string;
  outlet_group_id: string | null;
  sub_outlet_group_id: string | null;
  is_active: number;
}

export const formKategoriOutlet: IFormKategoriOutlet = {
  picture_url: null,
  name: '',
  outlet_group_id: null,
  sub_outlet_group_id: null,
  is_active: 1,
};

export interface IFormMerchant {
  is_official_partner: string;
  picture_url: any;
  name: string;
  email: string;
  phone: string;
  is_price_editable: boolean;
  is_active?: number;
}

export const formMerchant: IFormMerchant = {
  is_official_partner: '1',
  picture_url: null,
  name: '',
  email: '',
  phone: '',
  is_price_editable: false,
};

export interface IFormOutlet {
  owner: string;
  image: any;
  name: string;
  id?: string | null;
  merchant_id: string | null;
  fixed_sharing_fee: number;
  percentage_sharing_fee: string;
  address: string;
  operational_coverage_area: number;
  email: string;
  working_hour: Array<any>;
  bank_account_name: string | null;
  bank_account_number: string;
  bank_account_holder: string;
  location: string;
  lat: number;
  long: number;
  description: string;
  picture_url: any;
  merchant_outlet_operational_hours: Array<any>;
  temp_merchant_outlet_operational_hours: Array<any>;
  phone_number: string;
  outlet_category_id: Array<string>;
  type_delivery: string;
  outlet_group_id: string | null;
  is_draft: number;
  payment_partner_code: string | null;
  is_active: number;
}

export interface IFormOperationalHour {
  id: string;
  day: string;
  is_open: number;
  merchant_outlet_id: string;
  open: string;
  close: string;
}

export const formOutlet: IFormOutlet = {
  lat: 0,
  long: 0,
  owner: '',
  image: null,
  name: '',
  merchant_id: null,
  fixed_sharing_fee: 0,
  percentage_sharing_fee: '0',
  address: '',
  operational_coverage_area: 0,
  email: '',
  working_hour: [],
  bank_account_name: null,
  bank_account_number: '',
  bank_account_holder: '',
  location: '0,0',
  picture_url: null,
  merchant_outlet_operational_hours: [...workingDays],
  temp_merchant_outlet_operational_hours: [...initalWorkingDays],
  description: '',
  phone_number: '',
  outlet_category_id: [],
  type_delivery: 'all',
  outlet_group_id: null,
  is_draft: 1,
  is_active: 1,
  payment_partner_code: null,
};

export interface IDataTypePopupItem {
  name: string;
  price: number;
  category_item: string;
}

export interface IParamsKategoriOutlet {
  page: number;
  limit: number;
  q?: string;
  outlet_group_id?: string;
  layanan_id?: string;
  sort_by: string;
  order: string;
}

export interface IParamsMerchant {
  page: number;
  limit: number;
  q?: string;
  outlet_group_id?: string;
  outlet_category_id?: string;
  layanan_id?: string;
  sort_by: string;
  order: string;
}

export interface IParamsMerchantOutlet {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
  merchant_ids?: string;
  ids?: string;
  outlet_category_ids?: string;
  outlet_category_id?: string;
  outlet_group_ids?: string;
  outlet_group_id?: string;
  sort_by: string;
  order: string;
  rating?: string;
  start_rating?: number;
  end_rating?: number;
}

export interface IParamsReview {
  page: number;
  limit: number;
  q?: string;
  merchant_id?: string;
  merchant_outlet_id?: string;
  sort_by: string;
  order: string;
  rating?: string;
}

export interface IFormReview {
  id: string;
  order_invoice_number: string;
  comment: string;
  rating: number;
  created_at: any | null;
  user_name: string;
  merchant_name: string;
  merchant_outlet_name: string;
}

export const formReview: IFormReview = {
  id: '',
  order_invoice_number: '',
  comment: '',
  rating: 0,
  created_at: null,
  user_name: '',
  merchant_name: '',
  merchant_outlet_name: '',
};

export interface IParamsAdminOutlet {
  page: number;
  limit: number;
  q?: string;
  merchant_outlet_id?: string;
  sort_by: string;
  order: string;
}
