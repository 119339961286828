import React from 'react';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import './index.scss';

interface Props {
  title?: any;
  children?: any;
  footer?: any;
  width?: number;
  data: any;
}

const defaultChildrenHistory = (data?: any) => {
  const { created_by, created_at, updated_by, updated_at } = data;

  return (
    <div className="pt-4" style={{ borderTop: '1px solid lightgray' }}>
      <b>Dibuat Oleh</b>
      <Row>
        <Col span={12}>{created_by || '-'}</Col>
        <Col span={12}>
          {created_at ? moment(created_at).locale('id').format('DD MMMM YYYY; HH:mm') : '-'}
        </Col>
      </Row>
      <p />
      <b>Dimodifikasi Oleh</b>
      <Row>
        <Col span={12}>{updated_by || '-'}</Col>
        <Col span={12}>
          {updated_at ? moment(updated_at).locale('id').format('DD MMMM YYYY; HH:mm') : '-'}
        </Col>
      </Row>
    </div>
  );
};

export const modalHistory = (props: Props) =>
  Modal.info({
    content: props.children || defaultChildrenHistory(props.data),
    width: props.width || 400,
    icon: false,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    className: 'ant-modal-custom',
    closable: true,
    title: props.title || 'Riwayat',
  });
