import React, { createContext } from 'react';
import { useModelRoleList } from './context.role.list';
import { useModelRoleForm } from './context.role.form';
import { useModelUserList } from './context.user.list';
import { useModelUserForm } from './context.user.form';
import { useModelCustomerList } from './context.customer.list';
import { useModelCustomerForm } from './context.customer.form';
import { useModelKaryawanList } from './context.karyawan.list';
import { useModelKaryawanForm } from './context.karyawan.form';
import { useModelIntegratedCustomerList } from './context.integrated.customer.list';
import { useModelIntegratedCustomerForm } from './context.integrated.customer.form';
import { useModelIntegratedCustomerPropertyList } from './context.integrated.customer.property.list';
import { useModelPropertyList } from './context.property.list';

export const ContextUserManager = createContext<any>(null);

const ContextProviderUserManager: React.FC<{ children: any }> = ({ children }) => {
  const context = {
    useModelRoleList,
    useModelRoleForm,
    useModelUserList,
    useModelUserForm,
    useModelCustomerList,
    useModelCustomerForm,
    useModelKaryawanList,
    useModelKaryawanForm,
    useModelIntegratedCustomerList,
    useModelIntegratedCustomerForm,
    useModelIntegratedCustomerPropertyList,
    useModelPropertyList,
  };

  return <ContextUserManager.Provider value={context}>{children}</ContextUserManager.Provider>;
};

export default ContextProviderUserManager;
