import { notification } from 'antd';
import { checkIsUserOutlet } from '../../../pages/merchant/utils/reference.review';
import './customNotification.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface Props {
  type: NotificationType;
  title: string;
  description: string;
}

export const openNotificationWithIcon = (props: Props) => {
  const { type, title, description } = props;
  const { data } = checkIsUserOutlet();
  const className = data.status ? 'notification__outlet' : '';

  notification[type]({
    message: <b>{title}</b>,
    description,
    className,
  });
};
