import moment from 'moment';
import { IFormBanner } from '../model.marketing';

export const createBodyPayloadBanner = (data: any) => ({
  picture_url: data.imageUrl || data.picture_url,
  display_index: data.display_index,
  title: data.title,
  valid_from: moment(data.periode[0]).utcOffset(420).format('YYYY-MM-DDT00:00:00Z'),
  valid_until: moment(data.periode[1]).utcOffset(420).format('YYYY-MM-DDT23:59:59Z'),
  direct_url: data.direct_url,
  publish_on: data.publish_on,
  is_active: data.is_active ?? true,
  property_id: data.property_id ?? [],
  sub_property_id: data.sub_property_id ?? [],
  position_place: data.position_place,
});

export const setDetailBanner = (data: IFormBanner) => ({
  ...data,
  periode: [moment(data.valid_from), moment(data.valid_until)],
});
