import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderTransaksi from '../../pages/transaksi/context/context.provider.transaksi';

export const transaksiRoutes = [
  {
    path: '/transaksi/transaksi-masuk',
    exact: true,
    page: ['transaksi', 'transaksi masuk'],
    component: lazy(() => import('../../pages/transaksi/transaksi.masuk')),
    layout: Main,
    role: ['Superuser', 'Outlet', 'Owner'],
    title: 'Transaksi',
    context: ContextProviderTransaksi,
  },
  {
    path: '/transaksi/transaksi-masuk/detail/:id',
    exact: true,
    page: ['transaksi', 'transaksi masuk', 'detail'],
    component: lazy(() => import('../../pages/transaksi//detail/detail.transaksi.masuk')),
    layout: Main,
    role: ['Superuser', 'Outlet', 'Owner'],
    title: 'Transaksi',
    context: ContextProviderTransaksi,
  },
  // {
  //   path: '/transaksi/transaksi-keluar',
  //   exact: true,
  //   page: ['transaksi', 'transaksi masuk'],
  //   component: lazy(() => import('../../pages/transaksi/transaksi.keluar')),
  //   layout: Main,
  //   role: ['Superuser', 'Outlet', 'Owner'],
  //   title: 'Transaksi',
  //   context: ContextProviderTransaksi,
  // },
];
