/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';

export const useModelSettingList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [
      {
        no: 1,
        title: 'Menunggu Pembayaran',
        description: 'Pembayaran pesanan Muhammad Daffa berhasil. Kirim pesanan sebelum terlambat.',
      },
    ],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<any>();
  const [modal, setModal] = useState<{
    data: any;
    visible: boolean;
  }>({
    data: null,
    visible: false,
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  useEffect(() => {}, []);

  const showModalEdit = (data: any) =>
    setModal({
      data,
      visible: true,
    });

  const handleCancel = () =>
    setModal({
      data: null,
      visible: false,
    });

  const handleSubmit = (values: any) => console.log(values);

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    showModalEdit,
    visible: modal.visible,
    dataSelected: modal.data,
    handleSubmit,
    handleCancel,
  };
};
