import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllListKategori } from '../../../data/kategori/kategori';
import { getAllListMerchant } from '../../../data/merchant/merchant';
import { getAllListMerchantOutlet } from '../../../data/merchant/outlet';
import { UGetlistTransaksi } from '../../../usecase/transaksi/transaksi.masuk.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';
import { checkIsUserOutlet } from '../../merchant/utils/reference.review';
import { IParamsTransaksiMasuk } from '../model.transaksi';

const paramsFilter = {
  is_active: 1,
  order: 'desc',
  sort_by: 'created_at',
};

export const useModelTransaksiMasukList = () => {
  const { data: dataMerchant, status: statusMerchant } = checkIsUserMerchant();
  const { data: dataOutlet, status: statusOutlet } = checkIsUserOutlet();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsTransaksiMasuk>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    merchant_id: statusOutlet ? dataOutlet.merchant_id : statusMerchant ? dataMerchant.id : '',
    merchant_outlet_id: statusOutlet ? dataOutlet.id : '',
    start_date: '',
    end_date: '',
    outlet_group_id: '',
    outlet_group_parent_id: '',
    order: 'desc',
    status: '',
  });
  const [listLayanan, setListLayanan] = useState<Array<any>>();
  const [listSubLayanan, setListSubLayanan] = useState<Array<any>>();
  const [listMerchant, setListMerchant] = useState<Array<any>>();
  const [listOutlet, setListOutlet] = useState<Array<any>>();
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistTransaksi(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  const fetchLayanan = async (params: any) => {
    const temp: any = await getAllListKategori({ type: 'layanan', ...paramsFilter, ...params });
    setListLayanan(temp.data);
  };

  const fetchSubLayanan = async (params: any) => {
    const temp: any = await getAllListKategori({ type: 'sub-layanan', ...paramsFilter, ...params });
    setListSubLayanan(temp.data);
  };

  const fetchMerchant = async (params: any) => {
    const temp: any = await getAllListMerchant({ ...paramsFilter, ...params });
    setListMerchant(temp.data);
  };

  const fetchOutlet = async (params: any) => {
    const temp: any = await getAllListMerchantOutlet({ ...paramsFilter, ...params });
    setListOutlet(temp.data);
  };

  useEffect(() => {
    fetchLayanan(undefined);
    if (!statusMerchant) {
      fetchMerchant(undefined);
    } else if (!statusOutlet) {
      fetchOutlet({ merchant_ids: dataMerchant.id });
    }
  }, []);

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleFilter = (data: any) => {
    setParams({ ...params, ...data, page: 1 });
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleChangeValue = (value: string, type: string) => {
    if (type === 'layanan') {
      if (!value) setListSubLayanan([]);

      fetchSubLayanan({ parent_id: value });
    } else if (type === 'merchant') {
      if (!value) setListOutlet([]);

      fetchOutlet({ merchant_ids: value });
    }
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleFilter,
    handleSearch,
    setParams,
    handleChangePage,
    params,
    listLayanan,
    listSubLayanan,
    listMerchant,
    listOutlet,
    isUserMerchant: statusMerchant,
    dataMerchant: dataMerchant,
    isUserOutlet: statusOutlet,
    dataOutlet: dataOutlet,
    handleChangeValue,
  };
};
