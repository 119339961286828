const URL_API = process.env.REACT_APP_URL_API;
const URL_API_GATEWAY = process.env.REACT_APP_URL_API_GATEWAY;
const SERVICE_CLIENT = process.env.REACT_APP_URL_SERVICE_CLIENT;
const SERVICE_MERCHANT = process.env.REACT_APP_URL_SERVICE_MERCHANT;
const SERVICE_MARKETING = process.env.REACT_APP_URL_SERVICE_MARKETING;
const SERVICE_ORDER = process.env.REACT_APP_URL_SERVICE_ORDER;
const SERVICE_NOTIFICATION = process.env.REACT_APP_URL_SERVICE_NOTIFICATION;
const SERVICE_GATEWAY = process.env.REACT_APP_URL_SERVICE_GATEWAY;
const SERVICE_PROPERTY = process.env.REACT_APP_URL_SERVICE_PROPERTY;
const GMAPS_KEY = process.env.REACT_APP_GMAPS_KEY;

const FIREBASE_CONFIG = {
  API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  MESSAGE_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
};

export {
  URL_API,
  URL_API_GATEWAY,
  SERVICE_CLIENT,
  SERVICE_MERCHANT,
  SERVICE_MARKETING,
  SERVICE_ORDER,
  SERVICE_NOTIFICATION,
  SERVICE_GATEWAY,
  SERVICE_PROPERTY,
  GMAPS_KEY,
  FIREBASE_CONFIG,
};
