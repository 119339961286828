import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderMerchant from '../../pages/merchant/context/context.provider.merchant';

export const merchantRoutes = [
  {
    path: '/merchant/kategori-outlet',
    exact: true,
    page: ['merchant', 'kategori outlet'],
    component: lazy(() => import('../../pages/merchant/kategori.outlet')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/kategori-outlet/create',
    exact: true,
    page: ['merchant', 'kategori outlet', 'create'],
    component: lazy(() => import('../../pages/merchant/create/create.kategori.outlet')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/kategori-outlet/edit/:id',
    exact: true,
    page: ['merchant', 'kategori outlet', 'edit'],
    component: lazy(() => import('../../pages/merchant/edit/edit.kategori.outlet')),
    layout: Main,
    role: ['Superuser'],
    title: 'Kategori Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/merchant',
    exact: true,
    page: ['merchant', 'merchant'],
    component: lazy(() => import('../../pages/merchant/merchant')),
    layout: Main,
    role: ['Superuser'],
    title: 'Merchant',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/merchant/create',
    exact: true,
    page: ['merchant', 'merchant', 'create'],
    component: lazy(() => import('../../pages/merchant/create/create.merchant')),
    layout: Main,
    role: ['Superuser'],
    title: 'Merchant',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/merchant/edit/:id',
    exact: true,
    page: ['merchant', 'merchant', 'edit'],
    component: lazy(() => import('../../pages/merchant/edit/edit.merchant')),
    layout: Main,
    role: ['Superuser'],
    title: 'Merchant',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/merchant/detail/:id',
    exact: true,
    page: ['merchant', 'merchant', 'detail'],
    component: lazy(() => import('../../pages/merchant/detail/detail.merchant')),
    layout: Main,
    role: ['Superuser'],
    title: 'Merchant',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/outlet',
    exact: true,
    page: ['merchant', 'outlet'],
    component: lazy(() => import('../../pages/merchant/outlet')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/outlet/create',
    exact: true,
    page: ['merchant', 'outlet', 'create'],
    component: lazy(() => import('../../pages/merchant/create/create.outlet')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/outlet/edit/:id',
    exact: true,
    page: ['merchant', 'outlet', 'edit'],
    component: lazy(() => import('../../pages/merchant/edit/edit.outlet')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/outlet/admin/:id',
    exact: true,
    page: ['merchant', 'outlet', 'admin'],
    component: lazy(() => import('../../pages/merchant/admin/admin.outlet')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'Outlet',
    context: ContextProviderMerchant,
  },
  {
    path: '/merchant/review',
    exact: true,
    page: ['merchant', 'review'],
    component: lazy(() => import('../../pages/merchant/review')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Review',
    context: ContextProviderMerchant,
  },
  {
    path: `/merchant/review/detail/:id`,
    exact: true,
    page: ['merchant', 'review', 'detail'],
    component: lazy(() => import('../../pages/merchant/detail/detail.review')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Review',
    context: ContextProviderMerchant,
  },
];
