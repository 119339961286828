import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import profileIcon from '../../../assets/icons/profile-icon.svg';
import { dataRawUser, getUser } from '../../../utils/localStorage';

import { ReactComponent as IconEdit } from '../../../assets/icons/edit_profile.svg';
import { ReactComponent as IconPassword } from '../../../assets/icons/change_password.svg';
import { ReactComponent as IconRole } from '../../../assets/icons/information_role.svg';
import { ReactComponent as IconLogout } from '../../../assets/icons/logout.svg';
import { ICurrent } from './Box.Profile';
import { modalHistoryRole } from '../../Modal/ModalActionHistoryRole';
import Notif from './Notif';

interface Props {
  handleLogout: () => void;
  isMobile: boolean;
  current: ICurrent | undefined;
}

const DropdownProfile: React.FC<Props> = (props) => {
  const [hiddenText, setHiddenText] = useState<boolean>(false);
  const { handleLogout, isMobile, current } = props;
  const user = getUser();

  const handleShowInformation = () => {
    const data = {
      merchant_name: '',
      outlet_name: '',
      is_outlet: false,
    };

    if (current?.role === 'Outlet') {
      const dataOutlet = dataRawUser()?.outlet;

      data.merchant_name = dataOutlet.merchant.name;
      data.outlet_name = dataOutlet.name;
      data.is_outlet = true;
    } else if (current?.role === 'Owner') {
      data.merchant_name = dataRawUser()?.merchant.name;
      data.is_outlet = false;
    }

    modalHistoryRole({
      title: 'Informasi Merchant',
      data,
      isOutlet: data.is_outlet,
    });
  };

  const menu = (
    <Menu style={{ width: 170 }}>
      <Menu.Item key="0">
        <Link to="/profile">
          <p>
            {' '}
            <IconEdit width={16} className="me-2" /> Edit Profile
          </p>
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/change-password">
          <p>
            {' '}
            <IconPassword width={16} className="me-2" /> Ubah Password
          </p>
        </Link>
      </Menu.Item>
      {current?.role !== 'Superuser' && (
        <Menu.Item key="2">
          <div onClick={() => handleShowInformation()}>
            <p>
              {' '}
              <IconRole width={16} className="me-2" /> Informasi{' '}
              {current?.role === 'Outlet' ? 'Outlet' : 'Merchant'}
            </p>
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="3">
        <div onClick={() => handleLogout()}>
          <p>
            {' '}
            <IconLogout width={16} className="me-2" /> Keluar Aplikasi
          </p>
        </div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (window.innerWidth <= 1000) setHiddenText(true);

    // eslint-disable-next-line no-restricted-globals
    addEventListener('resize', handleCheckWidth);
  }, []);

  const handleCheckWidth = () => {
    const isHidden = window.innerWidth <= 1000;
    setHiddenText(isHidden);
  };

  return (
    <div className="box-profile" style={isMobile ? { width: '40%' } : {}}>
      {!hiddenText && (
        <div className="me-3">
          <p>HI! {user?.name}</p>
        </div>
      )}

      <Notif role={current?.role || ''} />

      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomCenter"
        overlayClassName="custom-dropdown-profile">
        <span className="ant-dropdown-link me-2" onClick={(e) => e.preventDefault()}>
          <img width={30} src={profileIcon} alt="profile-icon" />
        </span>
      </Dropdown>
    </div>
  );
};

export default DropdownProfile;
