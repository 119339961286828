import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistItem = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListItem = async (params: any) => {
  try {
    const res = await httpService.get('/all', { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailItem = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/merchant-product/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createItem = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/merchant-product`, data);
    // SuccessNotification({ description: 'Item berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateItem = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/merchant-product/${id}`, data);
    // SuccessNotification({ description: 'Item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusItem = async (id: string, data: any) => {
  try {
    const res = await httpService.patch(`${SERVICE_MERCHANT}/merchant-product/status/${id}`, data);
    // SuccessNotification({ description: 'Item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteItem = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/merchant-product/${id}`);
    SuccessNotification({ description: 'Item berhasil dihapus' });
    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const updateStatusDraftItem = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(`${SERVICE_MERCHANT}/merchant-product/${id}`, undefined, {
      params,
    });
    // SuccessNotification({ description: 'Item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
