export const checkMenuRoles = (roles: Array<string>) => {
  if (roles && roles.length > 0) {
    if (roles.includes('Superuser')) return 'Superuser';
    if (roles.includes('Admin')) return 'Admin';
    if (roles.includes('Owner')) return 'Owner';
    if (roles.includes('Outlet')) return 'Outlet';
  }

  return '';
};
