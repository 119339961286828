import { useEffect, useState } from 'react';

export const useModelMarketingList = () => {
  const [listData, setListData] = useState([]);

  useEffect(() => {}, []);

  return {
    listData,
    setListData,
  };
};
