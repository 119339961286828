import {
  getDetailVoucherTransaction,
  getlistInvoiceVoucherTransaction,
  getlistVoucherTransaction,
} from '../../data/marketing/voucher.transaction';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistInvoiceVoucherTransaction = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistInvoiceVoucherTransaction(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetlistVoucherTransaction = (params: any, id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistVoucherTransaction(params, id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailVoucherTransaction = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailVoucherTransaction();
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
