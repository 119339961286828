import { notification } from 'antd';

interface Props {
  message?: string;
  description?: string;
}

export const SuccessNotification = (props: Props) =>
  notification.success({
    message: 'Yeaa!',
    placement: 'topRight',
    description: props.description,
  });

export const ErrorNotification = (error: any) => {
  if (error?.response) {
    const { message, data } = error.response.data;
    if (message === 'INVALID_REQUEST') return Promise.reject(error);
    notification.error({
      message: message || 'Oops!',
      placement: 'topRight',
      description: data?.message || '',
    });
  } else {
    notification.error({
      message: 'Oops!',
      placement: 'topRight',
      description: 'Harap coba beberapa saat lagi',
    });
  }

  return Promise.reject(error);
};

export const ErrorNotificationNoValiation = (error: any) => {
  const { message, data } = error.response.data;

  notification.error({
    message: message || 'Oops!',
    placement: 'topRight',
    description: data?.message || '',
  });

  return Promise.reject(error);
};

export const WarningErrorNotification = (
  error: any,
  option?: { message?: string; description?: string },
) => {
  const { message, data } = error.response.data;

  notification.warning({
    message: option?.message || message || 'Oops!',
    placement: 'topRight',
    description: option?.description || data?.message || '',
  });

  return Promise.reject(error);
};

export const CustomErrorNotification = (description: string) =>
  notification.error({
    message: 'Oops!',
    placement: 'topRight',
    description,
  });
