import { ErrorNotification } from '../components/Notification/CustomNotification';
import { SERVICE_CLIENT } from '../config/keys';
import httpService from '../services/http.service';
import { deleteLocalUser, setUser } from '../utils/localStorage';

export const requestLogin = async (data: object) => {
  try {
    const res: any = await httpService.post(`${SERVICE_CLIENT}/auth/web`, data);
    setUser(res.data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const requestLogout = async (params: any) => {
  try {
    await httpService.get(`${SERVICE_CLIENT}/logout`, { params });
    deleteLocalUser();
    window.location.href = '/login';

    return {};
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const requestRefreshToken = async (refreshToken: string) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/refresh-token`, {
      headers: { refreshToken: refreshToken },
    });

    setUser(res.data);
    return res;
  } catch (error: any) {
    const {
      status,
      data: {
        data: { message },
      },
    } = error.response;
    if (status === 400 && message === 'invalid user ID') {
      deleteLocalUser();

      return (window.location.href = '/login');
    }

    return ErrorNotification(error);
  }
};

export const getIp = async () => {
  try {
    const res: any = await fetch('https://api.ipify.org/?format=json').then((res) => res.json());

    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
