import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UGetListAdminMerchantOutlet } from '../../../usecase/merchant/outlet.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsAdminOutlet } from '../model.merchant';

export const useModelOutletAdmin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsAdminOutlet>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    merchant_outlet_id: id,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    setLoading(true);
    try {
      const temp: any = await dispatch(UGetListAdminMerchantOutlet(params));
      setDataRequest({
        data: temp.data,
        pagination: temp.meta,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSearch = (q: string) => setParams({ ...params, q, page: 1 });

  const handleChangePage = (page: any) => setParams({ ...params, page });

  const handleChangeLimit = (limit: any) => setParams({ ...params, limit });

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleSearch,
    handleChangePage,
    handleChangeLimit,
    loading,
  };
};
