import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsUser } from '../model.usermanagement';
import { useParams } from 'react-router-dom';
import { UGetListIntegratedCustomerProperty } from '../../../usecase/user-management/integrated.customer.usecase';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelIntegratedCustomerPropertyList = () => {
  const { email, phone } = useParams();
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsUser>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    start_date: '',
    end_date: '',
  });
  const dispatch = useDispatch();

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  useEffect(() => {
    async function fetchList(email: string, phone: string, params: any) {
      const temp: any = await dispatch(UGetListIntegratedCustomerProperty(email, phone, params));
      setDataRequest({
        data: temp.data,
        pagination: temp.meta,
      });
    }

    if (email && phone) {
      fetchList(email, phone, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone]);

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleChangePage,
  };
};
