import {
  exportTransaksiReview,
  getDetailTransaksiReview,
  getlistTransaksiReview,
} from '../../data/merchant/review';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistTransaksiReview = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    return await getlistTransaksiReview(params);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailTransaksiReview = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    return await getDetailTransaksiReview(id);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UExportTransaksiReview = () => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    return await exportTransaksiReview();
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
