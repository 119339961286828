import React, { createContext } from 'react';
import { useModelKategoriOutletList } from './context.kategori.outlet.list';
import { useModelKategoriOutletForm } from './context.kategori.outlet.form';
import { useModelMerchantForm } from './context.merchant.form';
import { useModelMerchantList } from './context.merchant.list';
import { useModelOutletList } from './context.outlet.list';
import { useModelOutletForm } from './context.outlet.form';
import { useModelReviewList } from './context.review.list';
import { useModelReviewForm } from './context.review.form';
import { useModelOutletAdmin } from './context.outlet.admin';

export const ContextMerchant = createContext<any>(null);

const ContextProviderMerchant: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelKategoriOutletList,
    useModelKategoriOutletForm,
    useModelMerchantForm,
    useModelMerchantList,
    useModelOutletList,
    useModelOutletForm,
    useModelReviewList,
    useModelReviewForm,
    useModelOutletAdmin,
  };

  return <ContextMerchant.Provider value={context}>{props.children}</ContextMerchant.Provider>;
};

export default ContextProviderMerchant;
