import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

const BASE_URL = `${SERVICE_MERCHANT}/outlet-category`;

export const getlistKategoriOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${BASE_URL}`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListKategoriOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${BASE_URL}/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailKategoriOutlet = async (id: any) => {
  try {
    const res = await httpService.get(`${BASE_URL}/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createKategoriOutlet = async (data: any) => {
  try {
    const res = await httpService.post(`${BASE_URL}`, data);
    // SuccessNotification({ description: 'Kategori Outlet berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateKategoriOutlet = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${BASE_URL}/${id}`, data);
    // SuccessNotification({ description: 'Kategori Outlet berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusKategoriOutlet = async (id: string, params: any) => {
  try {
    const res = await httpService.patch(`${BASE_URL}/${id}`, undefined, { params });
    // SuccessNotification({ description: 'Kategori Outlet berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteKategoriOutlet = async (id: any) => {
  try {
    const res = await httpService.delete(`${BASE_URL}/${id}`);
    SuccessNotification({ description: 'Kategori Outlet berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
