import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useDebounce from '../../../utils/debounce';
import { defaultPagination, IPagination } from '../../../utils/pagination';
import { IParamsIntegratedCustomer } from '../model.usermanagement';
import {
  UExportIntegratedCustomer,
  UGetListIntegratedCustomer,
} from '../../../usecase/user-management/integrated.customer.usecase';

interface IData {
  data: Array<any>;
  pagination: IPagination;
}

export const useModelIntegratedCustomerList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsIntegratedCustomer>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    start_date: '',
    end_date: '',
    property_codes: [],
  });
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetListIntegratedCustomer(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSearch = (q: string) => {
    setParams({ ...params, q, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  const handleFilter = (data: any) => {
    setParams({ ...params, page: 1, ...data });
  };

  const handleExport = async () => {
    try {
      const res: any = await dispatch(UExportIntegratedCustomer());

      window.location = res.link;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleSearch,
    handleChangePage,
    handleFilter,
    handleExport,
  };
};
