import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_CLIENT } from '../../config/keys';
import httpService from '../../services/http.service';
import { setUpdateUser } from '../../utils/localStorage';

export const getlistUser = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/user`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListUser = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/user/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailUser = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/user/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createUser = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_CLIENT}/user`, data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateUser = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_CLIENT}/user/${id}`, data);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteUser = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_CLIENT}/user/${id}`);
    SuccessNotification({ description: 'User berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/user/me`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateCurrentUser = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_CLIENT}/user/${id}`, data);
    setUpdateUser(res.data);
    SuccessNotification({ description: 'Profile berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusUser = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_CLIENT}/user/${id}/block-account`, data);
    // SuccessNotification({ description: 'User status berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updatePassword = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_CLIENT}/reset-password`, data);
    SuccessNotification({ description: 'Password berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const exportCustomer = async () => {
  try {
    const res = await httpService.get(`${SERVICE_CLIENT}/user/export`);
    SuccessNotification({ description: 'Export data customer berhasil' });
    return res.data;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
