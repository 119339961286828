import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { useNavigate, useParams } from 'react-router-dom';
import {
  UCreateCampaign,
  UGetDetailCampaign,
  UGetListProperty,
  UUpdateCampaign,
} from '../../../usecase/marketing/campaign.usecase';
import { formCampaignBlast, IFormCampaignBlast } from '../model.marketing';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { createPayloadCampaign, setDetailCampaign } from '../utils/refernces.campaign';

export const useModelCampaignForm = () => {
  const { id } = useParams();
  const [form, setForm] = useState<IFormCampaignBlast>(formCampaignBlast);
  const [propertyList, setPropertyList] = useState<{ value: string; label: string }[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function fetchDetail(id: any) {
    const res: any = await dispatch(UGetDetailCampaign(id));
    const data = res.data as IFormCampaignBlast;
    setForm(setDetailCampaign(data));
  }

  async function fetchListProperty(params: { q?: string }) {
    const temp: any = await dispatch(UGetListProperty(params));

    const resultProperty = temp.map((property: any) => ({
      value: String(property.code),
      label: String(property.name),
    }));
    setPropertyList(resultProperty);
  }

  useEffect(() => {
    if (id) {
      fetchDetail(id);
    }
    fetchListProperty({ q: '' });
  }, [id]);

  const handleSubmit = async (values: any) => {
    const payload = createPayloadCampaign(values);

    try {
      if (id) {
        await dispatch(UUpdateCampaign(String(id), payload));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        await dispatch(UCreateCampaign(payload));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Campaign Blast',
          callback: () => navigate(-1),
        });
      }
    } catch (error: any) {
      console.log(error.response);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
    propertyList,
  };
};
