import {
  exportlistTransaksi,
  getDetailTransaksi,
  getlistTransaksi,
} from '../../data/transaksi/transaksi.masuk';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistTransaksi = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    return await getlistTransaksi(params);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailTransaksi = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    return await getDetailTransaksi(id);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UExportlistTransaksi = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    return await exportlistTransaksi(params);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
