import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getlistAllMerchant,
  getlistAllMerchantOutlet,
} from '../../../data/merchant/merchant.option';
import {
  UExportTransaksiReview,
  UGetlistTransaksiReview,
} from '../../../usecase/merchant/review.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { checkIsUserMerchant } from '../../items/utils/refrence.kategori.item';
import { IParamsReview } from '../model.merchant';
import { checkIsUserOutlet } from '../utils/reference.review';

export const useModelReviewList = () => {
  const { data: dataMerchant, status: statusMerchant } = checkIsUserMerchant();
  const { data: dataOutlet, status: statusOutlet } = checkIsUserOutlet();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsReview>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'orders.created_at',
    order: 'desc',
    merchant_id: statusOutlet ? dataOutlet.id : statusMerchant ? dataMerchant.id : '',
    merchant_outlet_id: statusOutlet ? dataOutlet.id : '',
  });
  const [listMerchant, setListMerchant] = useState<Array<any>>([]);
  const [listOutlet, setListOutlet] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const debouncedParams = useDebounce(params, 300);

  async function fetchMerchant() {
    const temp: any = await getlistAllMerchant({});
    setListMerchant(temp.data);
  }

  async function fetchOutlet(params: any) {
    if (!params.merchant_ids) return setListOutlet([]);

    const temp: any = await getlistAllMerchantOutlet(params);
    setListOutlet(temp.data);
  }

  useEffect(() => {
    fetchMerchant();

    if (statusMerchant) {
      fetchOutlet({ merchant_ids: dataMerchant.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistTransaksiReview(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleFilter = (data: any) => setParams({ ...params, ...data, page: 1 });

  const handleSearch = (q: string) => setParams({ ...params, q, page: 1 });

  const handleChangePage = (page: any) => setParams({ ...params, page });

  const handleExport = async () => {
    try {
      await dispatch(UExportTransaksiReview());
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleFilter,
    handleSearch,
    handleChangePage,
    params,
    handleExport,
    listMerchant,
    listOutlet,
    isUserMerchant: statusMerchant,
    dataMerchant,
    handleChangeMerchant: fetchOutlet,
  };
};
