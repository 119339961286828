import {
  createUser,
  deleteUser,
  exportCustomer,
  getAllListUser,
  getDetailUser,
  getlistUser,
  updateCurrentUser,
  updatePassword,
  updateStatusUser,
  updateUser,
} from '../../data/user-management/user';
import {
  setLoading,
  setLoadingTable,
  setLoadingUpdateUser,
} from '../../redux/layout/layout.actions';

export const UGetlistUser = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistUser(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListUser = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListUser(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailUser = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailUser(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateUser = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createUser(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateUser = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateUser(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteUser = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteUser(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UUpdateCurrentUser = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingUpdateUser(true));
    const res = await updateCurrentUser(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingUpdateUser(false));
  }
};

export const UUpdateStatusUser = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await updateStatusUser(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UUpdatePassword = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updatePassword(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UGetIsUserLogin = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const {
      data: { is_account_logged },
    } = await getDetailUser(id);
    return is_account_logged;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UExportCustomer = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await exportCustomer();
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
