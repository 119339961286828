/* eslint-disable import/prefer-default-export */
import React, { Fragment, lazy } from 'react';
import Login from '../components/layout/Login';
import Main from '../components/layout/Main';
import NotFound from '../components/layout/NotFound';
import ContextProviderProfile from '../pages/my-profile/context/contextprovider.profile';
import ContextProviderLogin from '../pages/auth/context/contextprovider.auth';
import { kategoriRoutes } from './sub-routes/kategori.routes';
import { merchantRoutes } from './sub-routes/merchant.routes';
import { transaksiRoutes } from './sub-routes/transaksi.routes';
import { marketingRoutes } from './sub-routes/marketing.routes';
import { userManagerRoutes } from './sub-routes/user.manager.routes';
import { itemsRoutes } from './sub-routes/items.routes';
import { itemsOutletRoutes } from './sub-routes/items.outlet.routes';
import { settingRoutes } from './sub-routes/setting.routes';
import { chatRoutes } from './sub-routes/chat.routes';

const noContext: React.FC<{ children: any }> = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

const menus = [
  {
    path: '/login',
    exact: true,
    page: ['login'],
    component: lazy(() => import('../pages/auth')),
    layout: Login,
    role: [''],
    title: 'Login',
    context: ContextProviderLogin,
  },
  {
    path: '/profile',
    exact: true,
    page: ['profile'],
    component: lazy(() => import('../pages/my-profile')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Dashboard',
    context: ContextProviderProfile,
  },
  {
    path: '/change-password',
    exact: true,
    page: ['change-password'],
    component: lazy(() => import('../pages/my-profile/change.password')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Dashboard',
    context: ContextProviderProfile,
  },
  {
    path: '/not-found',
    exact: true,
    page: ['not-found'],
    component: lazy(() => import('../pages/not-found')),
    layout: NotFound,
    role: [''],
    title: 'Not Found',
    context: noContext,
  },
  {
    path: '/dashboard',
    exact: true,
    page: ['dashboard'],
    component: lazy(() => import('../pages/dashboard')),
    layout: Main,
    role: ['Superuser', 'Owner', 'Outlet'],
    title: 'Dashboard',
    context: noContext,
  },
  ...kategoriRoutes,
  ...merchantRoutes,
  ...transaksiRoutes,
  ...marketingRoutes,
  ...userManagerRoutes,
  ...itemsRoutes,
  ...itemsOutletRoutes,
  ...settingRoutes,
  ...chatRoutes,
];

export { menus };
