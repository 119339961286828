import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UCreateMerchant,
  UGetDetailMerchant,
  UUpdateMerchant,
} from '../../../usecase/merchant/merchant.usecase';
import { formMerchant, IFormMerchant } from '../model.merchant';

export const useModelMerchantForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormMerchant>(formMerchant);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailMerchant(id));
      const { merchant_pictures } = temp.data;
      setForm({
        ...temp.data,
        is_official_partner: String(temp.data.is_official_partner || 0),
        picture_url:
          merchant_pictures && merchant_pictures.length > 0
            ? merchant_pictures[0].picture_url
            : null,
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormMerchant) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('is_official_partner', values.is_official_partner);
    formData.append(
      'file',
      values.picture_url ? values.picture_url.originFileObj || values.picture_url : '',
    );
    formData.append('is_price_editable', values.is_price_editable ? '1' : '0');

    try {
      if (id) {
        formData.append('is_active', String(form.is_active));
        await dispatch(UUpdateMerchant(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      } else {
        formData.append('is_active', '1');
        await dispatch(UCreateMerchant(formData));
        modalSuccess({
          title: 'Kamu berhasil menambahkan data Merchant',
          callback: () => navigate(-1),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    handleSubmit,
  };
};
