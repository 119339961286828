import React, { Fragment } from 'react';
import { Col, Modal, Row } from 'antd';
import './index.scss';

interface Props {
  title?: any;
  children?: any;
  footer?: any;
  width?: number;
  data: any;
  isOutlet?: boolean;
}

const defaultChildrenHistory = (data?: any, isOutlet?: boolean) => {
  const { merchant_name = '-', outlet_name = '-' } = data;

  return (
    <div className="pt-4" style={{ borderTop: '1px solid lightgray' }}>
      <b>Merchant</b>
      <Row>
        <Col span={12}>{merchant_name}</Col>
      </Row>
      {isOutlet && (
        <Fragment>
          <br />
          <b>Outlet</b>
          <Row>
            <Col span={24}>{outlet_name}</Col>
          </Row>
        </Fragment>
      )}
    </div>
  );
};

export const modalHistoryRole = (props: Props) =>
  Modal.info({
    content: props.children || defaultChildrenHistory(props.data, props.isOutlet),
    width: props.width || 400,
    icon: false,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    className: 'ant-modal-custom',
    closable: true,
    title: props.title || 'Informasi User',
  });
