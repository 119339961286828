/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { UDeleteCampaign, UGetlistCampaign } from '../../../usecase/marketing/campaign.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsCampaign } from '../model.marketing';

export const useModelCampaignList = () => {
  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsCampaign>({
    page: 1,
    limit: 10,
    q: '',
    sort_by: 'created_at',
    order: 'desc',
    campaign_type: '',
  });
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistCampaign(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
  }, [debouncedParams]);

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteCampaign(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => setParams({ ...params, q, page: 1 });

  const handleFilter = (data: any) => {
    let filter = {
      publish_start_at:
        data.publish && data.publish[0] ? moment(data.publish[0]).format('YYYY-MM-DD') : '',
      publish_end_at:
        data.publish && data.publish[1] ? moment(data.publish[1]).format('YYYY-MM-DD') : '',
      campaign_type: data.campaign_type,
    } as IParamsCampaign;

    setParams({ ...params, ...filter, page: 1 });
  };

  const handleChangePage = (page: number) => setParams({ ...params, page });

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    params,
    handleDelete,
    handleSearch,
    handleFilter,
    handleChangePage,
  };
};
