import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogin } from '../../../redux/auth/auth.actions';
import { URequestLogin } from '../../../usecase/auth.usecase';
import { handleRedirect } from '../../../utils/pages';
import { formAuth, IFormAuth } from '../model.auth';

export const useModelFormAuth = () => {
  const [form] = useState<IFormAuth>(formAuth);
  const [errorMessage, setErrorMessage] = useState<any>({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  const handleSubmit = async (value: any) => {
    try {
      await dispatch(URequestLogin(value));

      handleRedirect();
    } catch (error: any) {
      if (error) {
        const { data } = error;

        const tempErrorMessage = {
          email: data.data ? data.data.email || '' : '',
          password: data.data ? data.data.password || '' : '',
        };

        setErrorMessage(tempErrorMessage);
      }
    }
  };

  useEffect(() => {
    dispatch(resetLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    handleSubmit,
    errorMessage,
    setErrorMessage,
  };
};
