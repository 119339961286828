import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MERCHANT } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistItemOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-product-mapping`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getAllListItemOutlet = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-product-mapping/all`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailItemOutlet = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MERCHANT}/outlet-product-mapping/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createItemOutlet = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MERCHANT}/outlet-product-mapping`, data);
    // SuccessNotification({ description: 'Item berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateItemOutlet = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MERCHANT}/outlet-product-mapping/${id}`, data);
    // SuccessNotification({ description: 'Item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateStatusItemOutlet = async (id: string, data: any) => {
  try {
    const res = await httpService.patch(
      `${SERVICE_MERCHANT}/outlet-product-mapping/available/${id}`,
      data,
    );
    // SuccessNotification({ description: 'Item berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteItemOutlet = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MERCHANT}/outlet-product-mapping/${id}`);
    SuccessNotification({ description: 'Item berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
