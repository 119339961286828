import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UGetDetailItemOutlet,
  UUpdateItemOutlet,
} from '../../../usecase/items-outlet/item.outlet.usecase';
import { formItemsItem } from '../model.items.outlet';
import { createPayloadUpdateOutletItem, setDetailItemOutlet } from '../utils/reference';

export const useModelItemsItemForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>({
    formatted: formItemsItem,
    raw: formItemsItem,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailItemOutlet(id));
      setForm({
        formatted: setDetailItemOutlet(temp.data),
        raw: temp.data,
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmitItem = async (values: any) => {
    setLoading(true);
    const payload = createPayloadUpdateOutletItem(form.raw, values);
    try {
      if (id) {
        await dispatch(UUpdateItemOutlet(id, payload));
        modalSuccess({ title: 'Kamu berhasil memperbaruhi Item', callback: () => navigate(-1) });
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitVarian = () => {};

  const handleSubmitVarianItem = () => {};

  return {
    form: form.formatted,
    setForm,
    handleSubmitItem,
    handleSubmitVarian,
    handleSubmitVarianItem,
    loading,
  };
};
