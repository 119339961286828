import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { UGetDetailUser, UUpdateUser } from '../../../usecase/user-management/user.usecase';
import { formUser, IFormUser } from '../model.usermanagement';

export const useModelCustomerForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<IFormUser>(formUser);

  useEffect(() => {
    async function fetchDetail(id: any) {
      const temp: any = await dispatch(UGetDetailUser(id));
      setForm({
        ...temp.data,
        address: temp.data.property ? temp.data.property.property_name : '',
      });
    }

    if (id) {
      fetchDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: IFormUser) => {
    const formData = {
      name: values.name,
      email: values.email,
      phone_number: values.phone_number,
      is_account_logged: form.is_account_logged,
      is_active_account: form.is_active_account,
      is_verified: form.is_verified,
      email_is_verified: form.email_is_verified,
    };

    try {
      if (id) {
        await dispatch(UUpdateUser(id, formData));
        modalSuccess({ title: 'Data kamu berhasil diperbaharui', callback: () => navigate(-1) });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
  };
};
