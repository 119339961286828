import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { layoutReducer } from './layout/layout.reducer';
import { authReducer } from './auth/auth.reducer';
import { itemOutletReducer } from './item.outlet/item.outlet.reducer';
import { notificationReducer } from './notification/notification.reducer';

const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  itemOutlet: itemOutletReducer,
  notification: notificationReducer,
});

export default persistReducer(persistConfig, rootReducer);
