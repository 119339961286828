import moment from 'moment';
import { isDateNull } from '../../items.outlet/utils/reference';
import { IFormItemsItem } from '../model.items';

export const createBodyPayloadItem = (dataValues: IFormItemsItem, dataForm: IFormItemsItem) => {
  const formData = {
    name: dataValues.name || dataForm.name,
    description: dataValues.description || dataForm.description,
    product_base_price: dataValues.product_base_price || dataForm.product_base_price,
    merchant_product_category_id:
      dataValues.merchant_product_category_id || dataForm.merchant_product_category_id,
    merchant_id: dataValues.merchant_id || dataForm.merchant_id,
    stock_unit_id: dataValues.stock_unit_id || dataForm.stock_unit_id,
    is_discount: dataValues.is_discount ?? dataForm.is_discount,
    show_discount_in_percentage:
      dataValues.show_discount_in_percentage ?? dataForm.show_discount_in_percentage,
    discount_price: dataValues.discount_price || dataForm.discount_price || 0,
    discount_start_date: dataValues.discount_start_date || dataForm.discount_start_date,
    discount_end_date: dataValues.discount_end_date || dataForm.discount_end_date,
    is_allow_stock: dataValues.is_allow_stock ?? dataForm.is_allow_stock,
    is_active: dataValues.is_active || dataForm.is_active,
    is_draft: dataValues.is_draft || dataForm.is_draft,
    result_price: dataValues.result_price || dataForm.result_price || 0,
  };

  return formData;
};

export const setDetailListVariant = (data: Array<any> = []) => {
  const tempData = data.map((x: any) => {
    let merchantProductVariantItems = [];

    if (x.merchant_product_variant_items && x.merchant_product_variant_items.length > 0) {
      merchantProductVariantItems = x.merchant_product_variant_items.map((y: any) => ({
        ...y,
        discount_price: y.discount_price || 0,
        discount_start_date: isDateNull(y.discount_start_date),
        discount_end_date: isDateNull(y.discount_end_date),
      }));
    }

    return {
      ...x,
      is_multiple_choice: x.is_multiple_choice || 0,
      merchant_product_variant_items: merchantProductVariantItems,
    };
  });

  return tempData;
};

export const createPayloadVariant = (data: Array<any> = []) => {
  const checkIsRequiredAndIsDiscount = (data: any) => {
    if (!data) return 0;
    if (typeof data === 'boolean') return data ? 1 : 0;

    return data;
  };

  const payload = data.map((variant: any) => {
    let merchant_product_variant_items = [];

    if (
      variant.merchant_product_variant_items &&
      variant.merchant_product_variant_items.length > 0
    ) {
      merchant_product_variant_items = variant.merchant_product_variant_items.map(
        (variantItems: any) => ({
          variant_name: variantItems.variant_name,
          id: variantItems.id || '',
          variant_price: variantItems.variant_price,
          is_active: variantItems.is_active ? 1 : 0,
          is_discount: checkIsRequiredAndIsDiscount(variantItems.is_discount),
          discount_price: checkIsRequiredAndIsDiscount(variantItems.is_discount)
            ? variantItems.discount_price || 0
            : 0,
          discount_start_date: variantItems.discount_start_date
            ? moment(variantItems.discount_start_date).utcOffset(420).format('YYYY-MM-DDT00:00:00Z')
            : null,
          discount_end_date: variantItems.discount_end_date
            ? moment(variantItems.discount_end_date).utcOffset(420).format('YYYY-MM-DDT23:59:59Z')
            : null,
        }),
      );
    }

    return {
      id: variant.id || '',
      variant_name: variant.variant_name,
      merchant_product_id: variant.merchant_product_id,
      is_multiple_choice: variant.is_multiple_choice,
      is_required: checkIsRequiredAndIsDiscount(variant.is_required),
      merchant_product_variant_items,
    };
  });

  return payload;
};
