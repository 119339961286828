import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import { getAllListItemOutlet } from '../../../data/items-outlet/item';
import { getlistItem } from '../../../data/items/item';
import { setIsDelete } from '../../../redux/item.outlet/item.outlet.action';
import { UCreateItemOutlet } from '../../../usecase/items-outlet/item.outlet.usecase';
import { UDeleteItem } from '../../../usecase/items/item.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { checkIsUserOutlet } from '../../merchant/utils/reference.review';
import { IParamsItem } from '../model.items.outlet';
import { createPayloadOutletItem } from '../utils/reference';

export const useModelItemListPopup = () => {
  const { data } = checkIsUserOutlet();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsItem>({
    page: 1,
    limit: 5,
    q: '',
    merchant_id: data.merchant_id,
    merchant_product_id: '',
    order: 'desc',
    sort_by: 'created_at',
    is_active: '1',
    is_draft: '0',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();
  const { isDelete } = useSelector((state: { itemOutlet: any }) => state.itemOutlet);

  const paramsMyProduct = {
    q: '',
    merchant_outlet_id: data.id,
    merchant_product_id: '',
    order: 'desc',
  };

  async function fetchList(params: any) {
    setLoading(true);
    const temp: any = await getlistItem(params);
    setDataRequest({
      data: temp.data.map((val: any) => ({
        ...val,
        key: val.id,
      })),
      pagination: temp.meta,
    });
    setLoading(false);
  }

  async function fetchListMyProduct(params: any) {
    setLoading(true);
    const temp: any = await getAllListItemOutlet(params);
    setSelectedRowKeys(temp.data.map((val: any) => val.merchant_product_id));
    setLoading(false);
    await dispatch(setIsDelete(false));
  }

  useEffect(() => {
    if (isDelete && isDelete) fetchListMyProduct(paramsMyProduct);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelete]);

  useEffect(() => {
    fetchList(debouncedParams);
  }, [debouncedParams]);

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteItem(data.id));
    await fetchList(params);

    const tempSelectedRowKeys = [...selectedRowKeys];
    const indexOf = tempSelectedRowKeys.indexOf(data.id);

    tempSelectedRowKeys.splice(indexOf, 1);
    setSelectedRowKeys(tempSelectedRowKeys);
  };

  const handleSearch = (q: string) => setParams({ ...params, q, page: 1 });

  const handleChangePage = (page: any) => setParams({ ...params, page });

  const onSelectChange = async (values: any, flag: any) => {
    if (flag) {
      try {
        const payload = createPayloadOutletItem(values);

        await dispatch(UCreateItemOutlet(payload));
        modalSuccess({
          title: 'Kamu berhasil menambahkan Item',
          callback: async () => await fetchListMyProduct(paramsMyProduct),
        });
        return true;
      } catch (error) {
        return false;
      }
    }

    // else {
    //   const temp = [...selectedRowKeys];
    //   const index = temp.indexOf(values.id);

    //   if (index !== -1) {
    //     temp.splice(index, 1);

    //     setSelectedRowKeys(temp);
    //   }
    // }
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSearch,
    handleDelete,
    handleChangePage,
    params,
    loading,
    onSelectChange,
    selectedRowKeys,
  };
};
