import {
  getDetailIntegratedCustomer,
  getListIntegratedCustomer,
  getListIntegratedCustomerProperty,
  getListProperty,
  exportIntegratedCustomer,
} from '../../data/user-management/integrated.customer';
import {
  IParamsIntegratedCustomer,
  IParamsProperty,
} from '../../pages/user-manager/model.usermanagement';

import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetListIntegratedCustomer =
  (params: IParamsIntegratedCustomer) => async (dispatch: any) => {
    try {
      dispatch(setLoadingTable(true));
      const res = await getListIntegratedCustomer(params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      dispatch(setLoadingTable(false));
    }
  };

export const UGetDetailIntegratedCustomer =
  (email: string, phone: string) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await getDetailIntegratedCustomer(email, phone);
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const UExportIntegratedCustomer = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await exportIntegratedCustomer();
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UGetListIntegratedCustomerProperty =
  (email: string, phone: string, params: IParamsProperty) => async (dispatch: any) => {
    try {
      dispatch(setLoadingTable(true));
      const res = await getListIntegratedCustomerProperty(email, phone, params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      dispatch(setLoadingTable(false));
    }
  };

export const UGetListProperty = (params: IParamsProperty) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getListProperty(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};
