import {
  createKategoriOutlet,
  deleteKategoriOutlet,
  getAllListKategoriOutlet,
  getDetailKategoriOutlet,
  getlistKategoriOutlet,
  updateKategoriOutlet,
  updateStatusKategoriOutlet,
} from '../../data/merchant/kategori.outlet';
import { setLoading, setLoadingTable } from '../../redux/layout/layout.actions';

export const UGetlistKategoriOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getlistKategoriOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetAllListKategoriOutlet = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await getAllListKategoriOutlet(params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};

export const UGetDetailKategoriOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailKategoriOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UCreateKategoriOutlet = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await createKategoriOutlet(data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateKategoriOutlet = (id: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateKategoriOutlet(id, data);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UUpdateStatusKategoriOutlet = (id: string, params: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await updateStatusKategoriOutlet(id, params);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const UDeleteKategoriOutlet = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingTable(true));
    const res = await deleteKategoriOutlet(id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    dispatch(setLoadingTable(false));
  }
};
