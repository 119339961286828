import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalSuccess } from '../../../components/Modal/ModalSuccess';
import {
  UDeleteKategoriItem,
  UGetlistKategoriItem,
  UUpdateStatusKategoriItem,
} from '../../../usecase/items/kategori.item.usecase';
import { UGetlistAllMerchant } from '../../../usecase/merchant/merchant.option.usecase';
import useDebounce from '../../../utils/debounce';
import { defaultPagination, IData } from '../../../utils/pagination';
import { IParamsKategoriItem } from '../model.items';
import { checkIsUserMerchant } from '../utils/refrence.kategori.item';

export const useModelKategoriItemList = () => {
  const { data, status } = checkIsUserMerchant();

  const [dataRequest, setDataRequest] = useState<IData>({
    data: [],
    pagination: defaultPagination,
  });
  const [params, setParams] = useState<IParamsKategoriItem>({
    page: 1,
    limit: 10,
    q: '',
    merchant_id: status ? data.id : '',
    sort_by: 'created_at',
    order: 'desc',
  });
  const [listMerchant, setMerchantList] = useState<Array<any>>([]);
  const debouncedParams = useDebounce(params, 300);
  const dispatch = useDispatch();

  async function fetchMerchant() {
    const temp: any = await dispatch(UGetlistAllMerchant({}));
    setMerchantList(temp.data);
  }

  useEffect(() => {
    fetchMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchList(params: any) {
    const temp: any = await dispatch(UGetlistKategoriItem(params));
    setDataRequest({
      data: temp.data,
      pagination: temp.meta,
    });
  }

  useEffect(() => {
    fetchList(debouncedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleSetStatus = async (data: any, status: boolean) => {
    try {
      const formParams = {
        is_active: status ? 1 : 0,
      };

      await dispatch(UUpdateStatusKategoriItem(data.id, formParams));
      modalSuccess({
        title: 'Status Data berhasil diperbaharui',
        callback: () => {},
      });
      fetchList(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data: any) => {
    await dispatch(UDeleteKategoriItem(data.id));
    fetchList(params);
  };

  const handleSearch = (q: string) => {
    setParams({ ...params, q });
  };

  const handleFilter = (data: any) => {
    setParams({ ...params, merchant_id: data, page: 1 });
  };

  const handleChangePage = (page: any) => {
    setParams({ ...params, page });
  };

  return {
    listData: dataRequest.data,
    pagination: dataRequest.pagination,
    handleSetStatus,
    handleDelete,
    listMerchant,
    handleFilter,
    params,
    handleSearch,
    handleChangePage,
    isUserMerchant: status,
    dataMerchant: data,
  };
};
