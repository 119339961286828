import { Moment } from 'moment';

export interface IFormVoucher {
  id?: string;
  coupon_code: string;
  delivery_type: string;
  picture_url: string;
  terms_and_conditions: string;
  valid?: Array<any>;
  name: string;
  nominal_voucher: number;
  valid_from?: string;
  valid_until?: string;
  voucher_type: string;
  payment_channel: Array<string> | null;
  max_discount: number | null | string;
  min_transaction: number | null | string;
  limit_per_priod: number | null | string;
  limit_per_day: number | null | string;
  limit_per_user: number | null | string;
  new_user_only: boolean;
  is_visible: boolean;
  outlet_group?: Array<{
    outlet_group_id: string | null;
    outlet_group_name: string;
    sub_outlet_groups?: Array<{
      sub_outlet_group_id: string;
      sub_outlet_group_name: string;
    }>;
    merchants?: Array<{
      merchant_id: string;
      merchant_name: string;
    }>;
    outlets?: Array<{
      merchant_outlet_id: string;
      merchant_outlet_name: string;
    }>;
    outlet_categories?: Array<{
      outlet_category_id: string;
      outlet_category_name: string;
    }>;
  }>;
  show_in_percentage: boolean;
  is_active: boolean;
}

export const formVoucher: IFormVoucher = {
  coupon_code: '',
  delivery_type: '',
  picture_url: '',
  terms_and_conditions: '',
  name: '',
  nominal_voucher: 0,
  valid_from: '',
  valid_until: '',
  voucher_type: 'discount',
  payment_channel: [],
  max_discount: null,
  min_transaction: null,
  limit_per_priod: null,
  limit_per_day: null,
  limit_per_user: null,
  new_user_only: false,
  is_visible: true,
  outlet_group: [
    {
      outlet_group_id: null,
      outlet_group_name: '',
    },
  ],
  show_in_percentage: false,
  valid: [],
  is_active: true,
};

export interface IFormBanner {
  picture_url: any;
  display_index: number | null;
  title: string;
  valid_from: string;
  valid_until: string;
  direct_url: string;
  publish_on: string;
  is_active: boolean;
  property_id?: Array<string>;
  sub_property_id?: Array<string>;
  position_place: string | null;
  periode?: Array<Moment>;
}

export const formBanner: IFormBanner = {
  picture_url: null,
  display_index: null,
  title: '',
  valid_from: '',
  valid_until: '',
  direct_url: '',
  publish_on: 'mobile_app',
  is_active: true,
  property_id: [],
  sub_property_id: [],
  position_place: null,
  periode: [],
};

export interface IFormCampaignBlast {
  title: string;
  picture_url: any;
  publish_at: any;
  publish_time: any;
  publish_today: boolean;
  recipient_files: '-';
  publish_type: 'all' | 'all_tenant' | 'email' | 'wa' | null;
  publish_on_notif: {
    description: string;
    sub_title: string;
  } | null;
  publish_on_wa: {
    description: string;
    sub_title: string;
  } | null;
  publish_on_email: {
    description: string;
    sub_title: string;
  } | null;
  publish_on_tenant: string | null | undefined;
  category: string | null;
}

export const formCampaignBlast: IFormCampaignBlast = {
  title: '',
  picture_url: '',
  publish_at: null,
  publish_time: null,
  publish_today: false,
  recipient_files: '-',
  publish_type: 'all',
  publish_on_notif: null,
  publish_on_wa: null,
  publish_on_email: null,
  publish_on_tenant: undefined,
  category: null,
};

export interface IParamsVoucher {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  voucher_type?: string;
  valid_from?: string;
  valid_until?: string;
  outlet_group_ids?: string;
  outlet_sub_group_ids?: string;
}

export interface IParamsVoucherTransaction {
  page: number;
  limit: number;
  status: 'DONE' | 'PENDING' | 'CANCELED' | '';
}

export interface IParamsBanner {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  valid_from?: string;
  valid_until?: string;
  is_active?: boolean | string;
}

export interface IParamsVoucherListInvoice {
  page: number;
  limit: number;
  order: string;
  sort_by: string;
  voucher_id?: string;
  user_id?: string;
  status?: string;
}

export interface IParamsCampaign {
  page: number;
  limit: number;
  q?: string;
  sort_by: string;
  order: string;
  publish_start_at?: string;
  publish_end_at?: string;
  campaign_type?: 'wa' | 'notif' | 'email' | '';
}
