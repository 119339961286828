import React, { createContext } from 'react';
import { useModelTransaksiMasukList } from './context.transaksi.masuk.list';

export const ContextTransaksi = createContext<any>(null);

const ContextProviderTransaksi: React.FC<{ children: any }> = (props) => {
  const context = {
    useModelTransaksiMasukList,
  };

  return <ContextTransaksi.Provider value={context}>{props.children}</ContextTransaksi.Provider>;
};

export default ContextProviderTransaksi;
